import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss'


class Contect extends Component{
    constructor(props){
        super(props);
        this.state={
            ss:"1"
        }
    }
    render(){
        return(
            <div className="contect">
                <div className="lotteryContact">
                    <div className="main">
                        <div className="title">聯繫我們</div>
                        <div className="article">澳門六合彩將竭誠為您提供最及時、最便捷、及滿意的服務。若有問題可透過以下方式與我們取得聯繫。</div>
                        <ul className="contact">
                            <li>
                                <img src="http://cdn-qq.duomaids.com/macaujc/pc/img/email.png" alt="" />
                            </li>
                            <li>
                                <span>email：momarksix@gmail.com</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default Contect
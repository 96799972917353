import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss';
import AccordionExmple from './mainMobile'

class Faq extends Component{
    constructor(props){
        super(props);
        this.state={
            navInd:"1"
        }
    }
    navChange(ind){
        this.setState({
            navInd:ind
        })
    }
    render(){
        const {navInd } = this.state;
        return(
            <div className="faq">
                <div className="navHeader">
                    <span className={navInd==='1'?'span_active':''} onClick={()=>{this.navChange('1')}}>FAQ</span>
                    <span className={navInd==='2'?'span_active':''} onClick={()=>{this.navChange('2')}}>技术支持</span>
                </div>
                <div className="faq-center"  >
                    <div className="mainText" style={{display:navInd==='1'?'block':'none'}}>
                        <div className='mainText-pc'>
                            <div className="title">
                                FAQ
                            </div>
                            <div className="main">
                                <div>
                                1、澳門六合彩在什麼時間段可以購買？
                            </div><p>
                                答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。
                            </p><div>
                                2、需要多少錢才可以購買？
                            </div><p>
                                答：澳門六合彩注項單位為每注10澳幣。
                            </p><div>
                                3、單張彩票可以購買多少金額？
                            </div><p>
                                答：購買者可對其選定的投注號碼進行單倍或多倍投注，投注倍數範圍為1-100倍。單張彩票的投注金額最高不得超過1000澳幣。
                            </p><div>
                                4、人在外地是否可以購買澳門六合彩？
                            </div><p>
                                答：很抱歉，澳門六合彩只在區域性固定站點銷售。
                            </p><div>
                                5、我應如何確認是否中獎？
                            </div><p>
                                答：您可在澳門六合彩的銷售站點查詢確認、或在澳門六合彩官網的「開獎公告」處查詢核對中獎號碼。
                            </p><div>
                                6、如何能進一步確認開獎結果的正確性？
                            </div><p>
                                答：您可在澳門六合彩官網查看即時開獎公告與開獎視頻，或查看歷史開獎期號的視頻回放。
                            </p><div>
                                7、購買彩券後可以退款不買嗎？
                            </div><p>
                                答：抱歉，根據《奖券规例》規定獎券彩票一經發出給參加者或經參加者確認，參加者不得以任何理由更換、取消或撤回。
                            </p><div>
                                8、中獎之後領獎的步驟是什麼樣的？
                            </div><p>
                                答：所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。逾期未兌獎視為棄獎，棄獎獎金納入彩票公益金。
                            </p><div>
                                9、如果我的彩券受到污染或損壞是否可以兌獎？
                            </div><p>
                                答：根據《奖券规例》規定任何獎券彩票（包括損毀彩票）不會獲得派發獎金或發還退款，除非該彩票可憑其獨有的序列號碼辨證。
                            </p></div>
                        </div>
                        <div className="mainText-mobile">
                            <AccordionExmple/>
                        </div>
                    </div>
{/* // */}
                    <div className="mainText" style={{display:navInd==='2'?'block':'none'}}>
                        <div className="title">
                        技術支持
                        </div><div className="main"><div>
                            最低硬件系統要求：
                        </div><p>
                            1、可以接上互聯網的計算機；
                        </p><p>
                            2、區域寬帶；
                        </p><p>
                            3、Windows , Mac OS X , Linux , Android操作系統其中之一；
                        </p><p>
                            4、Internet Explorer瀏覽器v10.0 或以上，Mozilla Firefox (瀏覽器v3.0 或以上)，Opera (瀏覽器v8.0 或以上)，Chrome(瀏覽器v6.0 或以上)，Safari (瀏覽器v4.0 或以上)。
                        </p></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Faq
import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss'
import axios from 'axios';
import Utils from '../../utils'

class CountDown extends Component{
    constructor(props){
        super(props);
        this.state={
            recoverTime:null,
            classRefresh:false
        }
    }
    componentDidMount(){
        // console.log(Util.TimeDown('2020-05-05 00:00:000'))
        this.onRefresh();
    }
    
    TimeDown(params) {
        // console.log(params);
        let endTIME = params.substring(0,10)+" 21:30:00";
        // console.log(endTIME)
        //结束时间
        let endDate = new Date(endTIME.replace(/-/g,"/"));
        //当前时间
        let nowDate = new Date();
        //相差的总秒数
        let totalSeconds = parseInt((endDate - nowDate) / 1000);
        //天数
        let days = Math.floor(totalSeconds / (60 * 60 * 24));
        //取模（余数）
        let modulo = totalSeconds % (60 * 60 * 24);
        //小时数
        let hours = this.change(Math.floor(modulo / (60 * 60)));
        modulo = modulo % (60 * 60);
        //分钟
        let minutes =this.change( Math.floor(modulo / 60));
        //秒
        let seconds = this.change(modulo % 60);
        // console.log(totalSeconds);
        if (totalSeconds<0) {
            this.setState({recoverTime:"00:00:00"})
            return;
        }
        //输出到页面
        this.setState({
            recoverTime: (days==0?hours: Number(days)*24+Number(hours))  + ":" + minutes + ":" + seconds
        })

        //延迟一秒执行自己
        setTimeout( () => {
            this.TimeDown(endTIME);
        }, 1000)
    }
    change(t){
        if(t<10){
        return "0"+t;
        }else{
        return t;
        }
    }
    onRefresh(){
        // console.log(this.props.CountDown,Utils.Time().substring(0,16),Utils.Tomorrow());
        if(this.props.CountDown && this.props.CountDown!=''){
            // console.log('系统时间')
            this.TimeDown(this.props.CountDown+'0');
        }else{
            if(Utils.Time().substring(0,16) > Utils.Time().substring(0,10)+" 21:30:00"){
                // console.log('九点半后')
                this.TimeDown(Utils.Tomorrow()+' 21:30:000');
            }else{
                // console.log('九点半前')
                this.TimeDown(Utils.Time().substring(0,10)+' 21:30:000');
            }
        }
        // this.TimeDown(this.props.CountDown+'0');
        this.setState({
            classRefresh:true
        })
       setTimeout(()=>{
        this.setState({
            classRefresh:false
        })
       },1200)
    }
    render(){
        const{recoverTime,classRefresh} =this.state;
        return(
            
            <div className="countDown" style={{color:this.props.live?'#fff':""}}> 
                <span>{recoverTime==null?'0':recoverTime.substring(0,1)}</span>
                <span>{recoverTime==null?'0':recoverTime.substring(1,2)}</span>
                <span className="dian">:</span>
                <span>{recoverTime==null?'0':recoverTime.substring(3,4)}</span>
                <span>{recoverTime==null?'0':recoverTime.substring(4,5)}</span>
                <span className="dian">:</span>
                <span>{recoverTime==null?'0':recoverTime.substring(6,7)}</span>
                <span>{recoverTime==null?'0':recoverTime.substring(7,8)}</span>
                <span className={`${'Refresh'} ${classRefresh===true?'cRefresh':''}`} 
                 style={{display:this.props.live?'block':"none"}} 
                 onClick={()=>{this.onRefresh()}}></span> 
            </div>
        )
    }
}
export default CountDown
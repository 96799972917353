import React,{ Component} from 'react'
import moment from 'moment';
import axios from 'axios';
import API from "../../api/api"
import envconfig from "../../api/envconfig";
import Util from '../../utils/index';
import nowTime from '../../utils/nowTimes';
import Datas from '../../api/data'
import './index.scss';
import LiveHeader from '../../component/liveTvHeader';
import LiveTv from '../../component/liveTv';
import CountDown from '../../component/countDown';
import HttpFlv from './refiv'
import BgVideo from './bg-video'
import Videos from './video'

import { Input,DatePicker } from 'antd';
const { Search } = Input;

class Live extends Component{
    constructor(props){
        super(props);
        this.state={
            navIndex:"2",
            daysInd:"1",
            liveTvShow:false,
            liveDtat:{
                issue: "0000000",
                lotteryId: 2032,
                openCode: "00,00,00,00,00,00,00",
                openTime: "0000-00-00 00:00:00",
                pet: "鼠",
                videoUrl: "/lottery/video/2020/2032/2020101.mp4"
                // http://video.58shenghuojia.com/lottery/video/2020/2032/2020101.mp4
            },
            videoUrl:"",
            screen:false,
            ServerTimeData:[],
            IssueOpenInfoData:[],
            CurrentInfoData:[],
            HistoryOpenInfoData:[],
            HistoryOpenInfoDataII:[]
        }
    }
    componentDidMount(){
        this.HistoryOpenInfo(2032,Util.Time().substring(0,10));
        this.interval = setInterval(()=>{
            let tiem = nowTime(new Date(),'1');
            if(tiem==='开奖时间'){
                this.setState({
                    navIndex:'1'
                })
            }else{
                this.setState({
                    navIndex:'2'
                });
                this.HistoryOpenInfo(2032,Util.Time().substring(0,10));
                clearInterval(this.interval);
            }
        },1000)
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    // 开奖历史（今日）
    HistoryOpenInfo(lotteryId,issueNum){
        axios({
            method: 'post',
            url:envconfig.postUrl+'HistoryOpenInfo',
            data: {
                lotteryId: lotteryId,  //彩种id
                issueNum:issueNum    //期数
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        HistoryOpenInfoData:res.data.data,
                        HistoryOpenInfoDataII:res.data.data,
                    },()=>{
                        // console.log(this.state.HistoryOpenInfoData)
                    })
                }
            }).catch( err =>{
                console.log(err)
        })
    }
    navChange(ind){
        this.setState({
            navIndex:ind
        })
    }
    daysChange(ind){
        this.setState({
            daysInd:ind
        })
    }
    lookLive(data){
        this.setState({
            liveTvShow:true,
            liveDtat:data,
            videoUrl:data.videoUrl
        },()=>{
            console.log(this.state)
        })
        if(document.getElementById("video1")){
            let myVideo = document.getElementById("video1");
            myVideo.pause();
        }
    }
    hideLive = (e)=>{
        this.child.playPause();
        this.setState({
            liveTvShow:false
        });
    }
    setLiveData(value,name){
        // console.log(value,name)
        let data = this.state.HistoryOpenInfoDataII;
        let arr = [];
        if(data.length>0&&(value.length===7 || value.length===3)){
            // console.log(value,'hha ')
            data.forEach( element => {
                if(element.issue === value+'' || element.issue.substring(4,7) === value+''){
                    arr.push(element)
                }
            })
            this.setState({
                HistoryOpenInfoData:arr
            })
        }
        if(!value){
            this.setState({
                HistoryOpenInfoData:data
            })
        }
    }
    liveliView(){
        const { HistoryOpenInfoData }= this.state;
        if(HistoryOpenInfoData.length>0){
            return(
                HistoryOpenInfoData.map((item,index)=>{
                    return(
                        <div className="live-history-view" key={index} onClick={()=>{this.lookLive(item)}} >
                            <div>
                                <img src={require('../../img/repeat.png')} alt=""/>
                                <img src={require('../../img/live.png')} alt=""/>
                            </div>
                            <p className='live-history-view-p'>
                                <span>{item.lotteryId}</span> 第 <span className="red">{item.issue}</span> 期 開獎視頻
                            </p>
                            <p className='live-history-view-mobile-p'>
                                <span>澳门六合彩</span>
                                <span>第{item.issue}期 開獎視頻</span> 
                            </p>
                        </div>
                    )
                })
            )
        }
    }
    liveShow(){
        const { liveTvShow,liveDtat,videoUrl } = this.state;
        return(
            <div className='liveShow' style={{display:liveTvShow===true&&liveDtat!=null?'block':'none'}}>
                <div className="contens">
                    <div className="cha" onClick={()=>{this.hideLive()}}>
                        <img src={require('../../img/cha.png')} alt=""/>
                    </div>
                    <LiveHeader liveDtat={liveDtat} />
                    <LiveTv videoUrl={videoUrl===''?'/lottery/video/2020/2032/2020101.mp4':videoUrl} onRef={(ref)=>{ this.child  = ref}} />
                </div>
            </div>
        )
    }
    render(){
        const { navIndex,daysInd,videoUrl,screen} = this.state;
        return(
            <div className="live">
                <div className="live-top">
                    <div className="nav">
                        <span className="live-name">开奖视频</span>
                        <div>
                            <span>即時開獎驗證：</span> 開獎現場直播，與中央電視台1套同步，開獎過程100%公開公正！
                        </div>
                    </div>
                    <div className="header navHeader">
                        <span className='span_active' >澳門六合彩</span>
                        {/* <span className={navIndex==='2'?'span_active':''} onClick={()=>{this.navChange('2')}}>澳門十分六合彩</span> */}
                    </div>
                </div>
                <div className="live-mobile-top">
                    <div className='navHeader-mobile' style={{display:'none'}}>
                        <span>澳门六合彩</span>
                    </div>
                    <div className="nav-mobile">
                        <span>即時開獎驗證：</span> 
                        <p>開獎現場直播，與中央電視台1套同步，開獎過程100%公開公正！</p>
                    </div>
                </div>
                <div className="live-center">
                    <div className={[screen===false?'live-view':'live-view-screen']}>
                    {/* <HttpFlv  classNam="fivs"/> */}
                        
                        {
                            navIndex==='1'?
                            <BgVideo lotteryId={"2032"}/>
                            // <Videos classNam="fivs"  />
                            :
                            <div className='noLive'>
                                <p className="tetxs">澳門六合彩攪珠直播視頻開播時間為每晚 <span className="red">21:30-21:45</span> (北京時間)，敬請查看。</p>
                                <CountDown CountDown={''}  live={true} />
                            </div>
                            
                        }
                        <div className='tvZhibo' style={{display:navIndex=='1'?'block':'none'}}>直播</div>
                        {/* <div className='tvLogo'></div> */}
                        <img className='tvLogo' style={{display:screen===false&&navIndex=='1'?'block':'none'}} src={require('../../img/lhc_4.png')} alt=""/>
                    </div>
                    <div className="live-history">
                        <div className="live-history-top">
                            <div className="history-top-left">
                                <span className='lottery-name'>{navIndex==='2'?'澳門六合彩':'澳門十分六合彩'} 開獎視頻</span>
                                <span className='desc'>
                                    {navIndex==='2'?'開獎視頻回放，僅保留當年部分供用戶查詢觀看':'開獎視頻回放，僅保留三日供用戶查詢觀看'}
                                </span>
                            </div>
                            <div className="history-top-right">
                                <div className="days"  >
                                    <span className='choice'>今年</span>
                                </div>
                                {/* <div className="days"  style={{display:navIndex==='1'?'block':'none'}}>
                                    <span className={daysInd==='1'?'choice':''} onClick={()=>{this.daysChange('1')}}>今天</span>
                                    <span className={daysInd==='2'?'choice':''} onClick={()=>{this.daysChange('2')}}>昨天</span>
                                    <span className={daysInd==='3'?'choice':''} onClick={()=>{this.daysChange('3')}}>前天</span>
                                </div> */}
                                <div>
                                <Search
                                    placeholder="输入期號"
                                    onSearch={(value) =>{this.setLiveData(value,'pc')} }
                                    style={{ width: 120 ,borderRadius: 20}}
                                />
                                </div>
                            </div>
                        </div>
                        <div className='live-history-mobile-top'>
                            <div>開獎視頻</div>
                            <div>
                                <input type="text" placeholder='開獎查詢' onChange={(e)=>{this.setLiveData(e.target.value,'mobile')}} />
                                <img src={require('../../img/fdj.png')} alt=""/>
                            </div>
                        </div>
                        <div className='live-history-mobile-years'>
                            <div>
                                <span>今年</span>
                            </div>
                            <p>開獎視頻回放，僅保留當年部分供用戶查看</p>
                        </div>
                        <div className="liveli-view">
                            
                            {
                                this.liveliView()
                            }
                        </div>
                        <div className='live-mobile-foot'>
                            <div>
                                <span>沒有數據了</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {
                    this.liveShow()
                }
            </div>
        )
    }
}
export default Live

{/* <div class="backVideo">
    <bgvideo style="display: inline-block; position: relative; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); width: 640px; height: 368px;">
        <canvas width="1280" height="720" style="position: absolute; top: 0px; left: 0px; width: 640px; height: 100%; object-fit: contain; margin: 0px;"></canvas>
    </bgvideo>
</div> */}
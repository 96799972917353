import React,{Component} from 'react';
import './index.scss'
class Foot extends Component{
    constructor(props){
        super(props);
        this.state={
            kk:''
        }
    }
    render(){
        return(
            <div className="foot">
                版權所有 不得轉載 © 2020 澳門六合彩
            </div>
        )
    }
}
export default Foot
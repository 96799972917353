import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss'


class About extends Component{
    constructor(props){
        super(props);
        this.state={
            navInd:"1"
        }
    }
    navChange(ind){
        this.setState({
            navInd:ind
        })
    }
    render(){
        const { navInd } = this.state;
        return(
            <div className="about">
                <div className='about-header'>
                    <span className={navInd==='1'?'span_active':''} onClick={()=>{this.navChange('1')}}>关于我们</span>
                    <span className={navInd==='2'?'span_active':''} onClick={()=>{this.navChange('2')}}>游戏规范</span>
                    <span className={navInd==='3'?'span_active':''} onClick={()=>{this.navChange('3')}}>奖券规例</span>
                    {/* <span className={navInd==='4'?'span_active':''} onClick={()=>{this.navChange('4')}}>聯繫我們</span> */}
                </div>
                <img className="about-img" src={require('../../img/about.png')} alt=""/>
                <div className='navHeader'>
                    <span className={navInd==='1'?'span_active':''} onClick={()=>{this.navChange('1')}}>关于我们</span>
                    <span className={navInd==='2'?'span_active':''} onClick={()=>{this.navChange('2')}}>游戏规范</span>
                    <span className={navInd==='3'?'span_active':''} onClick={()=>{this.navChange('3')}}>奖券规例</span>
                </div>
                <div className="about-center">
                    <div style={{display:navInd==='1'?'block':'none'}}>
                        <div className="abouts">
                            <div className="title">關於我們</div>
                            <div className="text">
                                <p>澳門六合彩是從1至49個號碼中選出六個為中獎號碼的獎券，由香港賽馬會的附屬公司「香港馬會獎券澳門分公司」經辦。</p>
                                <p>澳門六合彩每晚攪珠一次，並於網絡現場直播。</p>
                                {/* <p>澳門十分六合彩每10分鐘攪珠一次，全天不間斷舉行，並于網絡現場直播。</p> */}
                            </div>
                        </div>

                        <div className="gameRule">
                            <div className="title">玩法規則</div>
                            <div className="lotteryRule">
                                <div className="text">
                                    <img src="http://cdn-qq.duomaids.com/macaujc/pc/img/logo_lg.png" />
                                        <table>
                                          <tbody>
                                            <tr>
                                                <td colSpan="3" className="theader"> 澳門六合彩注項單位為每注10澳幣。
                                                選擇複式或膽拖注項時，可以每注澳幣$5「部份注項單位」投注。
                                                有關之獎金則根據每注「部份注項單位」佔注項單位的份數計算。
                                                每期六合彩攪珠均從49個號碼中攪出七個號碼。首六個號碼稱為「攪出號碼」，
                                                而第七個號碼稱為「特別號碼」。
                                                    <p>獎項詳情如下：</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="one">頭獎</td>
                                                <td className="two">  選中6個「攪出號碼」</td>
                                                <td className="three"> 獎金會因應該期獲中頭獎注數而有所不同，每期頭獎獎金基金訂為不少於澳幣800萬元。</td>
                                            </tr>
                                            <tr>
                                                <td className="one">二獎</td>
                                                <td className="two">選中5個「攪出號碼」+「特別號碼」</td>
                                                <td className="three">獎金會因應該期獲中二獎注數而有所不同</td>
                                            </tr>
                                            <tr>
                                                <td className="one">三獎</td>
                                                <td className="two">選中5個「攪出號碼」</td>
                                                <td className="three">獎金會因應該期獲中三獎注數而有所不同</td>
                                            </tr>
                                            <tr>
                                                <td className="one"> 四獎</td>
                                                <td className="two">選中4個「攪出號碼」+「特別號碼」</td>
                                                <td className="three">固定獎金澳幣9,600元</td>
                                            </tr>
                                            <tr>
                                                <td className="one">五獎 </td>
                                                <td className="two"> 選中4個「攪出號碼」</td>
                                                <td className="three">固定獎金澳幣640元</td>
                                            </tr>
                                            <tr>
                                                <td className="one">六獎</td>
                                                <td className="two"> 選中3個「攪出號碼」+「特別號碼」</td>
                                                <td className="three">固定獎金澳幣320元</td>
                                            </tr>
                                            <tr>
                                                <td className="one"> 七獎</td>
                                                <td className="two">選中3個「攪出號碼」</td>
                                                <td className="three">固定獎金澳幣40元</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:navInd==='2'?'block':'none'}}>
                        <div className="gameRule">
                            <div className="title">遊戲規範</div>
                            <div className="lotteryRule">
                                
                                <div className="text">
                                <div style={{height:'40px'}}></div>
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                            <td colSpan="2" className="theader sheader">
                                                獎金的分配須受香港馬會獎券澳門分公司的獎券規例所約制。
                                                <p>獎金大致分配如下：</p>
                                            </td>
                                        </tr>
                                    <tr>
                                        <td className="first">各組獎金</td>
                                        <td className="second">獎金分配（每澳幣$10一注）</td>
                                    </tr>
                                    <tr>
                                        <td className="first">第一組獎金</td>
                                        <td className="second">45% x ﹝獎金基金減去第四、五、六及七組的總獎金﹞÷ 
                                        中獎單位每期頭獎獎金基金訂為不少於澳幣800萬元。
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="first">第二組獎金</td>
                                        <td className="second">5% x ﹝獎金基金減去第四、五、六及七組的總獎金﹞÷ 中獎單位</td>
                                    </tr>
                                    <tr>
                                        <td className="first">第三組獎金</td>
                                        <td className="second">40% x ﹝獎金基金減去第四、五、六及七組的總獎金﹞÷ 中獎單位</td>
                                    </tr>
                                    <tr>
                                        <td className="first">第四組獎金</td>
                                        <td className="second">每注澳幣9,600元</td>
                                    </tr>
                                    <tr>
                                        <td className="first">第五組獎金</td>
                                        <td className="second">每注澳幣640元</td>
                                    </tr>
                                    <tr>
                                        <td className="first"> 第六組獎金</td>
                                        <td className="second"> 每注澳幣320元</td>
                                        </tr>
                                    <tr>
                                        <td className="first">第七組獎金</td>
                                        <td className="second">
                                                         每注澳幣40元
                                        </td></tr>
                                        </tbody>
                                     </table>
              <p className="mark">
                獎金基金是指每期攪珠的總投注額的百分之五十四。 餘下的百分之四十六將根據博彩稅條例分配如下：
            </p><p className="marktail">
                (i) 獎券收益的徵稅(百分之二十五)；
            </p><p className="marktail">
                (ii) 獎券基金(百分之十五)；
            </p><p className="marktail">
                (iii) 香港馬會獎券澳門分公司的佣金(百分之六)。
            </p><p className="mark">
                第一組、第二組及第三組獎金基金之分配百分率可作調整，以盡量確保在任何時間內，每一有關獎金以一注項單位作計算：
            </p><p className="marktail">
                (i) 第一組獎金應最少為第二組獎金的兩倍；
            </p><p className="marktail">
                (ii) 第二組獎金應最少為第三組獎金的兩倍；
            </p><p className="marktail">
                (iii) 第三組獎金應最少為第四組獎金的兩倍；
            </p><p className="marktail">
                (iv) 第四組、第五組、第六組及第七組獎金亦可根據獎券規例而被調整。
            </p><p className="mark">
                中獎登記
            </p><p className="marktail">
                若獲中之獎金為每十元一注超過五百萬元，所有中獎彩票持票人士或經由電話投注服務的中獎人士，請於指定時間(公佈攪珠結果後至翌日下午5時)致香港馬會獎券澳門分公司在線服務系統登記。
            </p><p className="mark">
                領取資金
            </p><p className="marktail">
                所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。
            </p><p className="mark">
                獎券規例
            </p><p className="marktail">
                所有獎券注項均受香港馬會獎券澳門分公司規例所規限。如此通告與獎券規例有任何出入之處，一概以獎券規例為準。
            </p></div></div></div>
                    </div >
                    <div style={{display:navInd==='3'?'block':'none'}}>
                    <div className="rule"><div className="title">
        獎券規例
      </div><div className="detailRule"><h3>
          序言
        </h3><div className="orderRule"><div className="left">
            獎券規例
          </div><div className="right">
            本規例由香港馬會獎券澳門分公司董事局根據其章程大綱制定，藉以規管所有獎券活動。 
            本規例可隨時由香港馬會獎券澳門分公司董事局修訂，而有關修訂條文於香港馬會獎券澳門分公司董事局通過後生效。 
            有關修訂本規例的通告及／或根據本規例作出的決定及／或宣佈的通告，將張貼於營辦者總部的告示板及獎券發售地點的告示板，而不作另行通告。本規例可於營辦者總部及獎券發售地點供索閱，並於馬會網站供查閱。 
            所有向香港馬會獎券澳門分公司提交注項的參加者均被視作已同意接受本規例的約束及其所有的修訂條文、以及根據本規例不時作出的決定及宣佈的約束。
          </div></div><h3>
          第一章 定義 
        </h3><div className="orderRule"><div className="left">
            第一條
          </div><div className="right">
            在本規例中，各詞的定義如下：
            <p>
              「膽拖」指參加者選取的一個或以上的號碼，而該號碼或該等號碼均被包括在與參加者選定的其他號碼組成的每一個單式注項內。
            </p><p>
              「膽拖注項」指一項注項，其中參加者選取一個或以上的號碼作為膽拖。
            </p><p>
              「投注戶口」具有《博彩設施規例》所賦予的涵義。
            </p><p>
              「《博彩設施規例》」指馬會的博彩設施規例。
            </p><p>
              「投注終端機」具有《博彩設施規例》所賦予的涵義。 
            </p><p>
              「現金」指紙幣或硬幣形式的港元。
            </p><p>
              「現金券」具有《博彩設施規例》所賦予的涵義。
            </p><p>
              「機會」每一期攪珠中： 
              <span>
                (a) 對於獎券彩票中參與一個或以上單式注項者，指每一單式注項； 
              </span><span>
                (b) 對於獎券彩票中參與複式注項者，指由組成該複式注項之號碼可能排列組合出的每一單式注項； 
              </span><span>
                (c) 對於獎券彩票中參與膽拖注項者，指由作為膽拖的號碼與所有該獎券彩票中的其他號碼按照規定的數目組成的每一單式注項；
              </span><span>
                (d) 對於包含單式注項之電腦票，指其中的每一單式注項；
              </span><span>
                (e) 對於包含一隨機注項且該隨機注項同時是一複式注項之電腦票，指由組成該隨機注項之號碼可能排列組合出的每一單式注項。
              </span></p><p>
              「馬會」指香港賽馬會澳門分公司。
            </p><p>
              「電腦紀錄」指由營辦者所使用的電腦所出的紀錄。
            </p><p>
              「投注寶」具有《博彩設施規例》所賦予的涵義。
            </p><p>
              「損毀彩票」指任何遭撕毀、損壞或被塗改的實物獎券彩票，其損毀程度達致馬會認為難以辨認載於票上的資料的程度。
            </p><p>
              「攪珠」指選擇攪出號碼及特別號碼的程序。 
            </p><p>
              「攪出號碼」指在攪珠時自動選出的首六個號碼。
            </p><p>
              「覆述」指參加者在通過電話投注服務口頭作出注項時，職員就其注項向參加者作出的口頭覆述，且該口頭覆述載於馬會的錄音系統內。
            </p><p>
              「注項」包括：
              <span>
                (a) 一有效注項；
              </span><span>
                (b) 一名參加者的一項提交，透過獎券彩票或營辦者使用的任何設施，提交他對某一個或多個彩池中對某一期攪珠注項或一項多期攪珠注項的選擇，而該項提交涉及的注項可根據本規例而納入有關彩池內；
              </span><span>
                (c) 一單式注項、複式注項、膽拖注項或隨機注項。 
              </span></p><p>
              「特別號碼」指在攪珠時自動選出的第七個號碼。
            </p><p>
              「設施」具有《博彩設施規例》所賦予的涵義。在文義許可的情況下，「設施」包括投注地點。 
            </p><p>
              「香港」指中華人民共和國香港特別行政區。
            </p><p>
              「互聯網」具有《博彩設施規例》所賦予的涵義。 
            </p><p>
              「互聯網設施」具有《博彩設施規例》所賦予的涵義。
            </p><p>
              「注項款額」指參加者在一期攪珠中就一個或多個注項應支付的確實金額。
            </p><p>
              「獎券」具有《博彩稅條例》所賦予的涵義。
            </p><p>
              「獎券代理商」指經營辦者批準可由參加者在其營業場所提交注項及／或領取獎金或退款的任何人士、商號或公司。 
            </p><p>
              「政府獎券基金」具有《博彩稅條例》所賦予的涵義。
            </p><p>「獎券發售地點」指馬會的沙田及跑馬地馬場、場外的投注中心以及參加者可就獎券提交注項的任何地點。在文義許可的情況下，此定義包括馬會的官方網站及其他由營辦者核準或經營，容許透過互聯網提交注項的網站。 
            </p><p>
              「獎券彩票」指由投注終端機確定為有效後發出給參加者的實物票據；而對於營辦者使用而並無實物票據發出的任何設施，詳列該項注項的正式紀錄的部份，則被當作為有關的獎券彩票。
            </p><p>
              「多期攪珠注項」指一項為參與營辦者所許可的連續多期攪珠而提交的注項。
            </p><p>
              「複式注項」指一項所有可能的組合均分別構成單式注項。
            </p><p>
              「正式紀錄」：
              <span>
                (a) 除以下副款第 (b) 條另有規定外，當應用於獎券彩票或現金券時，或應用於經投注終端機、互聯網設施系統處理的交易時，「正式紀錄」指電腦紀錄；
              </span><span>
                (b) 當應用於參加者通過電話投注服務向職員口頭作出注項時，「正式紀錄」指由馬會的錄音系統證明參加者本人確認的覆述詳情；如無參加者的確認或錄音，則指電腦紀錄。
              </span></p><p>
              「營辦者」指香港馬會獎券澳門分公司。
            </p><p>
              「部份注項單位」指由營辦者在任何特定的獎券中接受的部份注項單位。
            </p><p>
              「參加者」指任何提交注項的人士。
            </p><p>
              「百分比扣數」指從每一個彩池或其任何部份中，依據《博彩稅條例》不時規定的稅率扣除的獎券稅、政府獎券基金分配及支付予營辦者的佣金總和。
            </p><p>
              「彩池」指每期攪珠由參加者作出之有效注項的總金額。
            </p><p>
              「獎金」指宣佈支付予每張獲獎彩票上每一注項單位的款項。
            </p><p>
              「獎金基金」指彩池減去百分比扣數。
            </p><p>
              「電腦票」指一張每一注項均為隨機注項的獎券彩票。
            </p><p>
              「隨機注項」指一項由營辦者使用的電腦隨機產生的號碼而組成的注項。
            </p><p>
              「退款」指無資格參與彩池的每項有效注項的金額或任何根據本規例由營辦者向參加者退還的注項金額。
            </p><p>
              「本規例」指在此列出的規例及其不時的修訂。「自助售票機」具有《博彩設施規例》所賦予的涵義。
            </p><p>
              「一期攪珠注項」指為參與一指定攪珠或提交注項後的第一期攪珠的注項。
            </p><p>
              「單式注項」指一項僅僅包含了某種特定獎券規定的最少選取號碼的注項。
            </p><p>
              「職員」指馬會及／或營辦者的僱員或獎券代理商在獎券發售地點僱用的僱員，並包括與馬會、營辦者及／或獎券代理商訂有合約協定的任何獨立承包人士的僱員。
            </p><p>
              「固定指示」指營辦者提供的設施，讓參加者能以相同的注項，參與連續期數的攪珠。
            </p><p>
              「電話投注服務」具有《博彩設施規例》所賦予的涵義。
            </p><p>
              「注項單位」指營辦者規定每一注項應提交的最低金額，且以該金額為基礎計算獎金。
            </p><p>
              「注項單位值」指參加者於每一注項所付的款額。
            </p><p>
              「有效注項」指經過本規例的程序後納入彩池內的每一注項。
            </p><p>
              「無效獎券彩票」指任何被宣佈為無效的獎券彩票。
            </p><p>
              「獲獎機會」指參加者的有效注項中任何有資格獲派獎金的機會。
            </p><p>
              「獲獎彩票」指任何包含獲獎機會的獎券彩票。
            </p></div></div><div className="orderRule"><div className="left">
            第二條
          </div><div className="right">
            表示一性別的詞包括所有其他性別，表示單數的詞包括複數，反之亦然。
          </div></div><div className="orderRule"><div className="left">
            第三條
          </div><div className="right">
            本規例各條規例（及副款）的標題將不影響本規例的推定或解釋。解釋任何規例時，可與任何其他規例作互相參照。
          </div></div></div><div className="detailRule"><h3>
          第二章 一般規例
        </h3><div className="orderRule"><div className="left">
            第四條
          </div><div className="right">
              營辦者的權職：
            <p>
              (a) 為進行獎券活動的目的，營辦者可使用與馬會可能協議的該些設施；
            </p><p>
              (b) 凡本規例未有列明的事宜，須交由營辦者決定，或作出最終定奪。
            </p></div></div><div className="orderRule"><div className="left">
            第五條
          </div><div className="right">
            酌情權。如根據本規例，營辦者有權決定或裁決某一項事情：
            <p>
              (a) 在作出決定或裁決時，營辦者有絕對的酌情權；
            </p><p>
              (b) 營辦者並無責任說明其決定或裁決的理由；
            </p><p>
              (c) 營辦者的決定或裁決是最終的決定。
            </p></div></div><div className="orderRule"><div className="left">
            第六條
          </div><div className="right">
            舉行攪珠
            <p>
              (a) 攪珠將由營辦者決定採用某方法及在某時某地舉行；
            </p><p>
              (b) 營辦者有權將攪珠延期。
            </p></div></div><div className="orderRule"><div className="left">
            第七條
          </div><div className="right">
            獎券設施
            <p>
              (a) 營辦者將提供設施，以便有意參加者可提交注項，及藉獎券彩票領取獎金及／或退款；
            </p><p>
              (b) 營辦者全權決定提供何等設施，並可無須通知，不時更改所提供的設施；
            </p><p>
              (c) 任何人士如使用或申請使用設施（視情況而定），則該人士會被視為已接受本規例、《博彩設施規例》及該些營辦者認為適用於有關設施的額外條款及條件；該些規例、條款及條件可不時由營辦者作出修訂；
            </p><p>
              (d) 除本規例另有規定者外，馬會所提供的設施不會擴展至接納以其他通訊方法所作的注項；
            </p><p>
              (e) 馬會全權決定其設施開放與公眾人士使用的時間；
            </p><p>
              (f) 各個獎券類別應由那種設施提供、每一設施適用的處理方式和程序（包括但不限於經每項設施構成有效注項所須的處理方式和接受注項程序）及每個獎券類別的提交注項時間均由馬會及營辦者決定；
            </p><p>
              (g) 參加者根據本規例申請使用的所有設施將只可作參加者的個人使用；
            </p><p>
              (h) 不管馬會及／或營辦者是否知悉（不論實際知道或憑法律構定或認定而知道）在一投注戶口、獎券彩票或現金券（視情況而言）中存在共有或受爭議的利益，馬會及營辦者將視投注戶口持有人或出示該獎券彩票或現金券之人士爲唯一對該投注戶口、獎券彩票或現金券有利益之人士。投注戶口持有人或出示獎券彩票或現金券之人士將就任何關於該投注戶口、獎券彩票或現金券的爭議利益所引致的任何責任，彌償馬會及營辦者。
            </p></div></div><div className="orderRule"><div className="left">
            第八條
          </div><div className="right">
            獎券的處理方式及程序
            <p>
              (a) 在開始出售獎券之前，營辦者會公告該類獎券的運作形式，該運作形式在營辦者作出更改的公告前將被持續採用；
            </p><p>
              (b) 所有注項必須以澳幣作出及結算；
            </p><p>
              (c) 除營辦者另行宣佈及本規例另有規定外，所有注項必須以下列方式繳付：
              <span>
                (i) 現金；
              </span><span>
                (ii) 現金券；
              </span><span>
                (iii) 投注戶口。
              </span></p><p>
              (d) 參加者不得撤回任何有效注項，但本規例下文第八條(e)的規定除外；
            </p><p>
              (e) 如某多期攪珠注項有資格獲獎金或退款：
              <span>
                (i) 持有實物獲獎彩票之參加者可以向營辦者繳回實物獲獎彩票以撤回對剩餘攪珠的注項，同時領取前述獎金或退款及剩餘攪珠的注項款額退款；
              </span><span>
                (ii) 透過投注户口繳付獲獎彩票之參加者不可撤回對剩餘攪珠的注項。
              </span></p><p>
              (f) 一項注項只有在經馬會按照本規例處理後以及被納入正式紀錄內，方成為有效注項。除非該項注項的注項款額已獲馬會接受及／或已從該參加者的投注戶口中扣除，否則該項注項不被視為已被馬會按照本規則處理；
            </p><p>
              (g) 任何遭營辦者拒絕或未獲營辦者接受的注項或部份注項不會構成一項有效注項；
            </p><p>
              (h) 無論任何情況下，營辦者皆無責任為注項款額未獲馬會接受及／或未從該參加者的投注戶口扣除的注項向該參加者支付獎金。
            </p></div></div><div className="orderRule"><div className="left">
            第九條
          </div><div className="right">
            獎券限制
            <p>
              (a) 營辦者可無須提出任何理由，而限制注項的數量及／或金額及／或拒絕或剔除參加者提交的一項注項的全部或部份，而不論有關注項是否已經由馬會處理及／或已獲營辦者接受；
            </p><p>
              (b) 任何根據本規例第九條(a)而被拒絕或剔除的注項或部份的注項不會構成一項有效注項；
            </p><p>
              (c) 對於被拒絕或剔除的注項或部份的注項，營辦者的責任（如有者）僅限於退款，退回參加者向馬會提交並已由馬會代表營辦者收取的金額中所被拒絕或剔除的注項款額；
            </p><p>
              (d) 營辦者根據本規例第九條(a)所作的決定對參加者具有約束力，參加者無權向營辦者追討由於有關限制、約束、拒絕參加及／或剔除而可能出現卻不能獲取的任何獎金及／或退款。
            </p></div></div><div className="orderRule"><div className="left">
            第十條
          </div><div className="right">
            獎券彩票 — 格式
            <p>
              營辦者將決定獎券彩票的格式，並指定某種獎券彩票適用於某一項注項種類。營辦者同時決定記錄獎券彩票或某一項注項的正式紀錄和電腦紀錄的內容。
            </p></div></div><div className="orderRule"><div className="left">
            第十一條
          </div><div className="right">
            獎券彩票及博彩設施 — 收費
            <p>
              營辦者可決定空白獎券彩票或使用任何營辦者為獎券活動而促致的設施是否需要收費。
            </p></div></div><div className="orderRule"><div className="left">
            第十二條
          </div><div className="right">
            實物獎券彩票 — 填寫
            <p>
              (a) 填寫實物獎券彩票時，適用下列條文：
              <span>
                (i) 獎券彩票必須用藍色原子筆填寫；
              </span><span>
                (ii) 須於票面格子內劃上垂直的劃線「│」符號以顯示選擇，劃線須完全劃在格子內；
              </span><span>
                (iii) 獎券彩票不得摺疊；
              </span><span>
                (iv) 不得在獎券彩票上作出修改或更正。
              </span></p><p>
              (b) 一名參加者購買電腦票應被視為已選擇電腦票上組成每一注項的號碼。
            </p></div></div><div className="orderRule"><div className="left">
            第十三條
          </div><div className="right">
            獎券彩票 — 有效性
            <p>
              (a) 儘管獎券彩票上或內已載錄資料，一項注項只有在經馬會按照本規例處理後以及被納入正式紀錄及彩池內，方成為有效注項。除非該項注項的注項款額已獲馬會接受及／或已從該參加者的投注戶口中扣除，否則該項注項不被視為已被馬會按照本規例處理；
            </p><p>
              (b) 一項有效注項是否合乎資格獲派獎金及／或發還退款應取決於該項注項的正式紀錄及是否已按照本規例處理，而不必考慮任何其他東西，包括實物獎券彩票上印有的任何資料；
            </p><p>
              (c) 凡以獎券彩票形式作出的注項，須經投注終端機機印生效或根據《博彩設施規例》確認，方有資格參與彩池；
            </p><p>
              (d) 參加者須全責確保發給或傳達給他的獎券彩票，正確地載錄了他提交的注項的所有詳情；
            </p><p>
              (e) 獎券彩票一經發出給參加者或經參加者確認，參加者不得以任何理由更換、取消或撤回；
            </p><p>
              (f) 若營辦者宣佈正式紀錄（或其任何部份）無效，該有關獎券彩票將被視作無效；所有記錄在經宣佈無效的正式紀錄（或其任何部份）的注項，將不符合資格獲發獎金。在本規例第十九條的規定下，所有受影響的注項將獲退還款項。
            </p></div></div><div className="orderRule"><div className="left">
            第十四條
          </div><div className="right">
            實物獎券彩票 — 遺失及損毀
            <p>
              (a) 若實物獎券彩票已被發出，營辦者授權馬會拒絕派發獎金予任何不能出示獎券彩票的參加者，或任何出示一張損毀彩票的參加者；
            </p><p>
              (b) 任何獎券彩票（包括損毀彩票）不會獲得派發獎金或發還退款，除非該彩票可憑其獨有的序列號碼辨證；
            </p><p>
              (c) 參加者必須在有關攪珠舉行的日期後起計的 7 天內，向馬會提出要求辨證一張已遺失的實物獎券彩票。參加者必須在有關攪珠舉行的日期後起計的 60 天內，向馬會提出要求辨證一張損毀彩票；
            </p><p>
              (d) 馬會或營辦者並無責任辨證一張已遺失的獎券彩票或損毀彩票。惟若馬會或營辦者同意進行辨證，馬會或營辦者可按其自行釐定的收費，收取費用；
            </p><p>
              (e) 即使一張已遺失的獎券彩票或損毀彩票能由馬會辨證，營辦者仍可指示馬會暫延派發獎金，直至有關攪珠舉行的日期後起計的 60 天後，方予派發。營辦者並可就該付款付加任何其認為適當的條件。
            </p></div></div><div className="orderRule"><div className="left">
            第十五條
          </div><div className="right">
            獎券彩票 — 無效
            <p>
              (a) 即使有關注項已納入彩池內，又即使已宣佈派發獎金，營辦者可無須提出任何理由，隨時宣佈任何獎券彩票無效；
            </p><p>
              (b) 宣佈某一張實物獎券彩票為無效的公佈，可以透過通告形式，於營辦者總部及／或發售該張實物獎券彩票的獎券發售地點的告示板上張貼。任何此類通知須自公佈時間起，張貼不少於24 小時；
            </p><p>
              (c) 此類公佈發表後，營辦者所接受的有關注項，須從彩池內扣除；
            </p><p>
              (d) 在符合本規例第十九條的規定下，所有被宣佈為無效的注項的款額將被發還。
            </p></div></div><div className="orderRule"><div className="left">
            第十六條
          </div><div className="right">
           未付足注款的注項
           <p>
             (a) 參加者必須在注項被接納或被獎券設施記錄或載入正式紀錄之時（以最早者計算），付足注項的注款；
           </p><p>
             (b) 如參加者在付足注款前，獎券活動已經停止，參加者仍須付足該注項的全部注款；
           </p><p>
             (c) 如參加者未有付足有關注項的注款，而根據正式紀錄，該注項本應獲派發獎金或發還退款，營辦者可宣佈有關獎券彩票無效。
           </p></div></div><div className="orderRule"><div className="left">
            第十七條
          </div><div className="right">
            固定指示
            <p>
              (a) 營辦者可接受固定指示，惟須符合以下條件：
              <span>
                (i) 唯有投注戶口持有人方可作出固定指示；
              </span><span>
                (ii) 該等固定指示必須正確及成功透過以書面致送馬會顧客服務部並遞交及由馬會接收；
              </span><span>
                (iii) 固定指示在營辦者決定的日期生效；
              </span><span>
                (iv) 任何固定指示的更改或取消，必須正確及成功透過書面致送馬會顧客服務部並遞交及由馬會接收；
              </span><span>
                (v) 營辦者可決定固定指示的更改或取消的生效日期。
              </span></p><p>
              (b) 除非有關投注戶口，在獎券攪珠之日的前一天辦公時間結束時，備有足夠存款支付固定指示的注項，否則所有以固定指示處理的注項，不得生效；
            </p><p>
              (c) 如投注戶口並無足夠存款，以支付固定指示的注項，則營辦者可暫停或取消其固定指示而毋須給予事前通知；
            </p><p>
              (d) 無論因何原因，若以固定指示處理的注項（不論全部或部份）未有包括在有關獎券內，馬會或營辦者亦無須向投注戶口持有人或任何其他人士負上任何責任；
            </p><p>
              (e) 營辦者可接受以部份注項單位投注的固定指示。任何投注戶口持有人就某注項通過固定指示提交的金額，而該金額雖少於一注項單位但屬營辦者可不時指定及接受的一個部份注項單位，將被視爲一部份注項單位。所有注項必須使用一注項單位或其整數倍數，或一個部份注項單位提交。營辦者可不受理任何並非使用注項單位或其整數倍數，或一個部份注項單位提交的固定指示；
            </p><p>
              (f) 如營辦者更改注項單位的最低金額或部份注項單位可接受的面額，則投注戶口持有人就其固定指示的每注項所提交的金額將被視爲修改後的注項單位的分數或倍數（如適用）。營辦者只會接受有關分數如該金額與營辦者指定及接受的一個部份注項 單位相同。直至投注戶口持有人根據本規例作出任何進一步的更改或取消，固定指示將被視爲已自動作出上述更改。
            </p></div></div><div className="orderRule"><div className="left">
            第十八條
          </div><div className="right">
            攪珠結果
            <p>
              (a) 營辦者將記錄攪珠結果及應付的獎金；
            </p><p>
              (b) 上述記錄應為正式結果，所有參加者應受此等結果約束。
            </p></div></div><div className="orderRule"><div className="left">
            第十九條
          </div><div className="right">
            獎金及退款 — 支付
            <p>
              (a) 所有獲獎彩票或退款申領，必須在有關攪珠舉行的日期後起計的 60 天內的任何工作天或在多期攪珠注項的情況下，在最後一期攪珠舉行的日期後起計的 60 天內的任何工作天，於一個獎券發售地點憑票領取款項；
            </p><p>
              (b) 凡獎金或退款，未有於有關攪珠舉行的日期後起計的 60 天內領取者，一概予以沒收；
            </p><p>
              (c) 營辦者全權決定以現金、支票或現金券或存入參加者作出注項時使用之投注戶口的方式，派發獎金或發還退款；
            </p><p>
              (d) 若然有一實物獎券彩票，該實物獎券彩票必須交還馬會或獎券代理商，方可獲發獎金或退款；
            </p><p>
              (e) 每張實物獎券彩票一經獲發獎金或退款，即成為營辦者的財物；
            </p><p>
              (f) 如正式紀錄顯示已就某一注項付訖獎金或退款，有關紀錄即為獎金或退款已支付的確證；
            </p><p>
              (g) 營辦者授權馬會只向提交符合正式紀錄的實物獎券彩票之人士，派發獎金或發還退款。馬會或營辦者並不會理會聲稱享有獎券彩票利益的任何其他人士有關獎金或退款的分配申索或爭議。根據本規例第七條(h)規定，出示獎券彩票的人士將就任何有關該獎券彩票或有關注項的利益爭議而產生的任何責任，彌償馬會及營辦者；
            </p><p>
              (h) 營辦者並無責任在任何特定時間內，派發獎金或發還退款。
            </p></div></div><div className="orderRule"><div className="left">
            第二十條
          </div><div className="right">
            獎金 — 修訂
            <p>
              (a) 凡獎金於公佈後，發覺有差誤或計算錯誤，營辦者有權宣佈將獎金修訂；
            </p><p>
              (b) 營辦者一旦發覺某項獎金有差誤或計算錯誤，即可行使修訂獎金的權力；
            </p><p>
              (c) 任何獲獎彩票持有人，凡仍未領取獎金者，均可獲派修訂後的獎金；
            </p><p>
              (d) 在符合本規例第二十條(f)至第二十條(h)的規定下，任何獲獎彩票持有人，如已領取獎金者，亦有權申領原有獎金與已修訂獎金的差額；
            </p><p>
              (e) 營辦者如公佈獎金修訂，須自行或要求馬會於營辦者及／或馬會總部張貼有關公佈的通告和／或以營辦者認為必要和可行的方式公佈有關修訂；
            </p><p>
              (f) 營辦者可為有資格領取修訂獎金的參加者，指定修訂獎金的申領期限及申領地點；
            </p><p>
              (g) 營辦者可規定聲稱有資格領取修訂獎金的參加者，在領取獎金前，向營辦者或馬會提供身份證明及留下聯絡地址。營辦者可訂明派發修訂獎金的任何條款；
            </p><p>
              (h) 營辦者指定修訂獎金派發期限過後，合乎資格的獲獎彩票持有人，如未有前往領取修訂獎金，或未能提供營辦者認為滿意的身份證明，或未能接受營辦者的派發條件，則該等未申領的獎金予以沒收；
            </p><p>
              (i) 每個投注戶口雖獲存入其使用投注戶口作出的注項的獎金，如該獎金其後經修訂，該投注戶口將根據差額扣賬或入賬；
            </p><p>
              (j) 如修訂獎金較原有獎金為少，營辦者有權放棄向已獲派獎金的參加者索還多派的獎金。
            </p></div></div><div className="orderRule"><div className="left">
            第二十一條
          </div><div className="right">
            獎金 — 登記
            <p>
              (a) 營辦者可指示馬會只派發獎金予已按照營辦者指定的方式登記的獲獎彩票持有人或作出有效注項而中獎的投注戶口持有人的一個投注戶口；
            </p><p>
              (b) 在此情況下，營辦者會通過馬會公佈申領獎金須辦理登記，並指明登記期限、地點及方法；
            </p><p>
              (c) 如獲獎彩票持有人，或其有效注項已中獎的投注戶口持有人，未有遵照指定時限和方式向馬會進行登記，營辦者可拒絕派發獎金，並沒收有關獎金。
            </p></div></div><div className="orderRule"><div className="left">
            第二十二條
          </div><div className="right">
            獎金及退款 — 申領的有效性
            <p>
              (a) 營辦者可指示馬會進行它認為需要的查詢，以確定某獎金或退款的申領是否有效。出示申領的參加者必須與馬會充份合作，否則營辦者可延遲或拒絕支付款項；
            </p><p>
              (b) 儘管某一張獎券彩票先前已被公佈為無效，在收到領取獎金的申請時，馬會仍可進行它認為適當的查詢，以確定該張獎券彩票如何被宣佈為無效。任何查詢均不會授予無效獎券彩票持有人任何獲領獎金的權利。
            </p></div></div><div className="orderRule"><div className="left">
            第二十三條
          </div><div className="right">
            攪珠的終止
            <p>
              (a) 營辦者可在任何時候終止任何一期攪珠，而無須提出任何理由；
            </p><p>
              (b) 所有被納入此終止攪珠的彩池之有效注項將獲全數退款；
            </p><p>
              (c) 營辦者對於被納入此終止彩池之注項除全數退款以外毋須負上任何責任。
            </p></div></div><div className="orderRule"><div className="left">
            第二十四條
          </div><div className="right">
            獎金及退款 — 沒收
            <p>
              任何根據本規例被沒收的獎金或退款或任何其他款項，將按照營辦者決定的方式撥入彩票公益金。
            </p></div></div><div className="orderRule"><div className="left">
            第二十五條
          </div><div className="right">
            職員 — 協助
            <p>
              任何協助投注者填寫獎券彩票的職員、馬會、營辦者及／或獎券代理商，均無須就該職員確實或據稱未能依照參加者要求填寫彩票而付上或代為付上任何責任。
            </p></div></div><div className="orderRule"><div className="left">
            第二十六條
          </div><div className="right">
            未成年或穿著校服人士 
            <p>
              (a) 任何未足十八歲人士或穿著校服人士，均不得或不准許：
              <span>
                (i) 提交注項或企圖領取獎金或退款；
              </span><span>
                (ii) 進入任何獎券發售地點，得到營辦者授權者除外。
              </span></p><p>
              (b) 如參加者經證實在提交注項時年齡不足十八歲，該參加者的注項可被宣佈為無效。該參加者原應獲發的獎金或退款須予以沒收；
            </p><p>
              (c) 馬會、營辦者及／或獎券代理商均有權向任何違反本規例第二十六條的未足十八歲或穿著校服人士，及／或任何協助或教唆該等違規的人士，要求他們彌償馬會、營辦者及／或獎券代理商因該等違規而可能蒙受的所有責任、檢控、申索、損失或損害。
            </p></div></div><div className="orderRule"><div className="left">
            第二十七條
          </div><div className="right">
            主管的權限
            <p>
              營辦者授權並且參加者接受，獎券發售地點的主管有權不給予任何理由：
              <span>
                (a) 拒絕接受任何人士為注項提交的款項；
              </span><span>
                (b) 取消對注項的接受；
              </span><span>
                (c) 勒令任何人士離開獎券發售地點。
              </span></p></div></div><div className="orderRule"><div className="left">
            第二十八條
          </div><div className="right">
             設施故障
            <p>
              (a) 如在一期攪珠舉行中，用以攪珠的機器發生故障，營辦者可以按照其決定的方式繼續舉行該期攪珠；
            </p><p>
              (b) 如因設施干擾或故障，以致某項注項未能載入營辦者的電腦紀錄和被納入於有關的彩池內，不論干擾或故障因何發生，營辦者的責任（如有者）僅限於退款，退回參加者的注項款額；
            </p><p>
              (c) 如一注項已成為有效注項，但其後設施發生故障、損壞或損毀，或又如有關正式紀錄損壞或損毀，以致有效注項不能被辨認或追索，不論故障、損壞或損毀因何發生，營辦者的責任（如有者）僅限於退款，退回參加者的注項款額。
            </p></div></div><div className="orderRule"><div className="left">
            第二十九條
          </div><div className="right">
             《博彩設施規例》的適用
            <p>
              《博彩設施規例》的規定將被視作為本規例的一部份。
            </p></div></div><div className="orderRule"><div className="left">
            第三十條
          </div><div className="right">
             免除責任
            <p>
              當執行本規例時，營辦者、馬會或獎券代理商（如有者）不會：
              <span>
                (a) 對參加者或其他人士所遭受的任何直接或間接的損失負責，儘管其損失是由於馬會、營辦者及／或獎券代理商、其僱員、代理人或承包人士，包括該代理人的僱員或該承包人士的僱員或任何其他人士任何有意或疏忽的行為或遺漏而引起的；
              </span><span>
                (b) 對由於任何設施的任何無效、干擾或故障（不論其因由）所引起的任何損失或損毀的申索負責；
              </span><span>
                (c) 受理任何因有關獎券活動所引起的申索。
              </span></p></div></div><div className="orderRule"><div className="left">
            第三十一條
          </div><div className="right">
             非先例
            <p>
              馬會或營辦者在某一情況下所行使的酌情權，將不構成一具約束力的先例，以使馬會或營辦者在另一情況下需要以相同或類似方式行使其酌情權。
            </p></div></div><div className="orderRule"><div className="left">
            第三十二條
          </div><div className="right">
             不放棄
            <p>
              營辦者對本規例任何條款不行使、或對該行使有所延誤、放寬或容忍，將不構成對該行使的放棄；營辦者對本條例的任何一次或部份執行或行使，將不對營辦者就本條例在將來的任何執行或行使作出局限。
            </p></div></div><div className="orderRule"><div className="left">
            第三十三條
          </div><div className="right">
             適用法律及司法管轄
            <p>
              本規例由澳門法律管轄。每一位參加者將服從澳門法院的獨立司法管轄權。
            </p></div></div></div><div className="detailRule"><h3>
          第三章 六合彩獎券
        </h3><div className="orderRule"><div className="left">
            第三十四條
          </div><div className="right">
            獎券形式
            <p>
              「六合彩」是從一至四十九（包括首尾兩數）或營辦者可能決定和公佈的該些號碼中攪珠選出攪出號碼及特別號碼的獎券。
            </p></div></div><div className="orderRule"><div className="left">
            第三十五條
          </div><div className="right">
            單式注項
            <p>
              在一單式注項中，參加者必須從四十九個號碼中選出六個號碼，每一單式注項為一次機會。
            </p></div></div><div className="orderRule"><div className="left">
            第三十六條
          </div><div className="right">
            複式注項
            <p>
              在一複式注項中，參加者必須從四十九個號碼中選擇最少七個號碼。為計算獨立的機會數目，所選號碼將組成每組由六個號碼組成的所有可能的組合。
            </p></div></div><div className="orderRule"><div className="left">
            第三十七條
          </div><div className="right">
            膽拖注項
            <p>
              在一項膽拖注項中，參加者必須從四十九個號碼中選擇最少七個號碼。為計算獨立的機會數目，所選號碼將組成每組由六個號碼組成的所有可能的組合，惟每一組合須包括參加者選為膽拖的全部號碼。
            </p></div></div><div className="orderRule"><div className="left">
            第三十八條
          </div><div className="right">
            獎金基金的分配
            <p>
              (a) 下列各項應從獎金基金中扣除：
              <span>
                (i) 第四組獎金，每個 9,600 元；
              </span><span>
                (ii) 第五組獎金，每個 640 元；
              </span><span>
                (iii) 第六組獎金，每個 320 元；
              </span><span>
                (iv) 第七組獎金，每個 40 元。
              </span></p><p>
              (b) 餘下的獎金基金，應當按下列方式分配：
              <span>
                (i) 第一組獎金基金 — 百分之四十五；
              </span><span>
                (ii) 第二組獎金基金 — 百分之十五;
              </span><span>
                (iii) 第三組獎金基金 — 百分之四十。
              </span></p></div></div><div className="orderRule"><div className="left">
            第三十九條
          </div><div className="right">
            獎金最少限額
            <p>
              (a) 本規例第三十八條之分配百分率可作調整，以盡量確保在任何時間內，每一有關獎金以一注項單位作計算：
              <span>
                (i) 第一組獎金應最少為第二組獎金的兩倍；
              </span><span>
                (ii) 第二組獎金應最少為第三組獎金的兩倍；
              </span><span>
                (iii) 第三組獎金應最少為第四組獎金的兩倍。
              </span></p><p>
              (b) 營辦者可以決定第一組獎金基金的最低額。
            </p></div></div><div className="orderRule"><div className="left">
           第四十條
          </div><div className="right">
            獲獎資格
            <p>
              (a) 每一機會將可獲派其有資格獲派的最高組別獎金而不可獲派較低組別獎金；
            </p><p>
              (b) 可參與獎金派發的資格如下：
              <span>
                (i) 凡選中六個攪出號碼之每一機會將分享第一組獎金基金；
              </span><span>
                (ii) 凡選中任何五個攪出號碼及特別號碼之每一機會將分享第二組獎金基金；
              </span><span>
                (iii) 凡選中任何五個攪出號碼但不包括特別號碼之每一機會將分享第三組獎金基金；
              </span><span>
                (iv) 凡選中任何四個攪出號碼及特別號碼之每一機會將獲得第四組獎金；
              </span><span>
                (v) 凡選中任何四個攪出號碼但不包括特別號碼之每一機會將獲得第五組獎金；
              </span><span>
                (vi) 凡選中任何三個攪出號碼及特別號碼之每一機會將獲得第六組獎金；
              </span><span>
                (vii) 凡選中任何三個攪出號碼但不包括特別號碼之每一機會將獲得第七組獎金。
              </span></p></div></div><div className="orderRule"><div className="left">
            第四十一條
          </div><div className="right">
            獎金計算
            <p>
              (a) 計算每一獲獎機會在第一組獎金基金、第二組獎金基金及第三組獎金基金中可獲得的金額時，如獲獎機會的注項單位值大於注項單位，則該注項單位值將被視為若干注項單位，數目由注項單位值除以注項單位所得決定；
            </p><p>
              (b) 當第一組、第二組或第三組（視情况而定）有超過一個獲獎機會或當第一組、第二組或第三組（視情况而定）的獲獎機會的注項單位值大於注項單位，該組的獎金基金應被除以獲獎機會的注項單位及部份注項單位的總數，而每一獲獎機會的注項單位應分得由此計算出的金額；
            </p><p>
              (c) 除規例第四十一條(d)另有規定外，如在第一組或第二組（視情况而定）中只有一個獲獎機會，而該獲獎機會使用部份注項單位提交，則應以該組的獎金基金乘以部份注項單位除以注項單位，而有關獲獎機會應獲得以此計算出的獎金。獎金基金的餘額應撥入下期攪珠第一組獎金基金；
            </p><p>
              (d) 如在第三組只有一個獲獎機會，且其使用部份注項單位提交，則應以第三組獎金基金乘以部份注項單位除以注項單位，而有關獲獎機會應獲得以此計算出的獎金。第三組獎金基金的餘額應按下列進行分配：
              <span>
                (i) 如同時存在第一組及第二組的獲獎機會，百分之七十五則撥入同一期攪珠的第一組獎金基金及餘下的百分之二十五撥入同一期攪珠的第二組獎金基金；
              </span><span>
                (ii) 如僅在第一組或第二組中存在獲獎機會，則撥入同一期攪珠存在獲獎機會的一組；
              </span><span>
                (iii) 如不存在第一組及第二組的獲獎機會，則撥入下期攪珠第一組獎金基金。
              </span></p><p>
              (e) 爲免除懷疑，就每一獲獎機會的部份注項單位而言，則應獲得該組獎金的注項單位所分得的金額乘以部份注項單位除以注項單位。
            </p></div></div><div className="orderRule"><div className="left">
           第四十二條
          </div><div className="right">
            無第四、第五、第六或第七組獎金
            <p>
              如在第四、第五、第六及第七組的任何一組、兩組、三組或全部中均沒有獲獎機會，則不應在獎金基金分配之前，按本規例第三十八條規定扣除有關組別的獎金。
            </p></div></div><div className="orderRule"><div className="left">
            第四十三條
          </div><div className="right">
            無第一組獎金
            <p>
              如在第一組中沒有獲獎機會，則第一組獎金基金將撥入下期攪珠第一組獎金基金。
            </p></div></div><div className="orderRule"><div className="left">
            第四十四條
          </div><div className="right">
            無第二組獎金
            <p>
              如在第二組並沒有獲獎機會，則第二組獎金基金將撥入下期攪珠第一組獎金基金。
            </p></div></div><div className="orderRule"><div className="left">
            第四十五條
          </div><div className="right">
            無第三組獎金
            <p>
              如在第三組中沒有獲獎機會，則第三組獎金基金應按下列進行分配：
              <span>
                (a) 如同時在第一組及第二組均有獲獎機會，百分之七十五則撥入同一期攪珠的第一組獎金基金及餘下的百分之二十五撥入同一期攪珠的第二組獎金基金；
              </span><span>
                (b) 如僅在第一組或第二組中有獲獎機會，則撥入同一期攪珠存在獲獎機會的一組；
              </span><span>
                (c) 如不存在第一組及第二組的獲獎機會，則撥入下期攪珠第一組獎金基金。
              </span></p></div></div><div className="orderRule"><div className="left">
            第四十六條
          </div><div className="right">
            無第一、第二及第三組獎金
            <p>
              如第一、第二及第三組均沒有獲獎機會，分配於此等組別之獎金基金將撥入下期攪珠第一組獎金基金。
            </p></div></div><div className="orderRule"><div className="left">
            第四十七條
          </div><div className="right">
            獎金基金的重新分配
            <p>
              (a) 如第四、第五、第六及第七組獎金的獎金總額超過獎金基金的百分之六十，本規例第三十八條的扣除只限於此百分率，而支付第四、第五、第六及第七組獎金所需款項之餘額，將由公益金撥支；
            </p><p>
              (b) 倘若獎金基金百分之六十的款額，加上將根據本規例第四十條所作的彩票公益金現存的總額，仍不足以全數支付本規例第三十八條規定的全部第四、第五、第六及第七組獎金款額，則第四、第五、第六及第七組的有獎金（包括支付予部份注項單位的獎金）將同時按比例調低，且第四組獎金仍應為第五組獎金的十五倍，第五組獎金仍應為第六組獎金的兩倍，第六獎金仍應為第七組獎金的八倍。之後，每一獎金必須調低至與注項單位最接近的整數倍數；
            </p><p>
              (c) 倘若獎金基金在支付第四、第五、第六及第七組獎金後，其餘額不足支付本規例第三十九條規定的第一、第二及第三組獎金的最款額，則差額部份將由公益金撥支，而第一、第二及第三組獎金應調低至與注項單位最接近的整數倍數；
            </p><p>
              (d) 倘若獎金基金作出支付第四、第五、第六及第七組獎金所需款項之預計扣數後，不足以支付第一、第二及第三組獎金的最低款額，則所有的獎金（包括支付予部份注項單位的獎金）將同時按比例調低，且第一組獎金應為第二組獎金的兩倍，第二組獎金應為第三組獎金的兩倍，第三組獎金應為第四組獎金的兩倍，第四組獎金應為第五組獎金的十五倍，第五組獎金應為第六組獎金的兩倍，而第六組獎金則應為第七組獎金的八倍。在必要時，所有獎金均應調低至與注項單位最接近的整數倍數。任何因此而產生的餘款將撥回彩票公益金。
            </p></div></div><div className="orderRule"><div className="left">
            第四十八條
          </div><div className="right">
            獎金調整
            <p>
              分配入不同組別的獎金基金應除以該組別中獲獎機會的注項單位及部份注項單位的總數，所得結果即為獎金。但如獎金數額不是注項單位的整數倍數，獎金應調低至與注項單位最接近的整數倍數。所有因此產生的餘款撥入彩票公益金。
            </p></div></div></div></div>
                    </div>
                    <div style={{display:navInd==='4'?'block':'none'}}>
                      <div className="title">聯繫我們</div>
                      <div data-v-edc61f8e="" className="about-content">
                          澳門六合彩將竭誠為您提供最及時、最便捷、及滿意的服務。若有問題可透過以下方式與我們取得聯繫。
                      </div>
                      <div className="about-detail">
                        <img src={require('../../img/xf.png')} alt=""/>
                        <p>momarksix@gmail.com</p>
                      </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default About
import React,{ Component} from 'react'
import {withRouter} from "react-router-dom";
import './index.scss';
import { Accordion, List } from 'antd-mobile';
import Hk6 from '../../../utils/hk6'
import Utils from '../../../utils/index';
import nowTime from '../../../utils/nowTimes';
import CountDown from '../../../component/countDown'
import HistoryMobile from '../history-mobile'
class LotteryMobile extends Component{
    constructor(porps){
        super(porps);
        this.state={
            lottery:'',
            show1:'none',
            show2:"none",
            openingTime:"其他时间"
        }
    }
    componentDidMount(){
        // this.saveOpeningTime()；
        this.interval = setInterval(()=>{
            let inter;
            let tiem = nowTime(new Date(),'2');
            this.setState({
              openingTime:tiem
            },()=>{
                // console.log(this.state.openingTime)
            })
            if(tiem==='滚动时间'){
                inter = setInterval(() => {
                    this.setState({
                        lottery:Utils.shuffle()
                    }) 
                }, 100);
            }else{
                clearInterval(inter); 
                this.setState({
                    lottery:this.props.openCode
                })
            }
        },1000)
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    // 判断是否在开奖时间
    saveOpeningTime(){
        let inter;
        if(Utils.fivTime()===true){
            inter = setInterval(() => {
                this.setState({
                    lottery:Utils.shuffle()
                }) 
            }, 100);
        }else{
            setTimeout(() => {
                this.setState({
                    lottery:this.props.openCode
                }) 
            }, 500);
        }
        setTimeout(() => {
            clearInterval(inter)
        }, 25*60*1000);
    }
    showHistory(){
        this.setState({
            show1:this.state.show1==='none'?'block':'none'
        })
    }
    routerPath(name){
        this.props.history.push(name)
    }
    onChange = (key) => {
        // console.log(key);
    }
    render(){
        let lotterys = this.props.openCode;
        // let lotterys = "";
        let lotteryHistory = this.props.HistoryDatas;
        const {lottery, show1,show2,openingTime} = this.state;
        return(
            <div className='lottery-mobile'>
                <div className="lottery-mobile-hrader">
                    <div className="issue">
                        <span>{this.props.lottery}</span>
                        <span> <span className="issue-num">第</span>{this.props.datas.issue?this.props.datas.issue:'********'}<span className="issue-num">期</span> </span>
                    </div>
                    <div className="endTime">
                        <div>下期開獎時間</div>
                        <div>{this.props.datas.endTime?this.props.datas.endTime.substring(0,10)+' 21:15:00':'00-00-00 00:00:00'}</div>
                    </div>
                </div>
                <div className="lottery-mobile-countDown">
                    <div>
                        <CountDown CountDown={this.props.datas.endTime} />
                        {/* <CountDown CountDown={''} />  */}
                    </div>
                    <div className="buttonS">
                        <div>
                            <span className='kaijiang' onClick={()=>{this.setState({show2:'block'})}}>開獎驗證</span>
                            <span className='zhibo' onClick={()=>{this.routerPath('/live')}}>直播</span>
                        </div>
                        <div onClick={()=>{this.routerPath('/post')}}>
                            <span className='hisyory-buts'>歷史開獎查詢</span>
                            <img src={require('../../../img/you.png')} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="lottery-mobile-history">
                    <ul className="lotteryNum">
                            {
                               openingTime==='滚动时间'?Hk6.lotteryView(lottery):Hk6.lotteryView(lotterys)
                            }
                            <li className="add">+</li>
                    </ul>
                </div>
                <div className='last-issue' onClick={()=>{this.showHistory()}}>
                    <Accordion defaultActiveKey="0" className="my-accordion" onChange={this.onChange}>
                        <Accordion.Panel header="查看近五期&nbsp;&nbsp;&nbsp; ">
                        </Accordion.Panel>
                    </Accordion>
                </div>
                <div style={{display:show1}}>
                    <HistoryMobile 
                        datas={lotteryHistory} 
                        lottery="澳門六合彩"
                    />
                </div>
                <div className="yzPupro" style={{display:show2}}>
                    <div className="pupBackgrount" onClick={()=>{this.setState({show2:'none'})}}></div>
                    <div className="pupViews">
                        <div className="pup-top">
                            <img src={require('../../../img/tan.png')} alt=""/>
                            <span>即時開獎驗證</span>
                        </div>
                        <div>
                            <p>
                            開獎現場直播，與中央電視台1套同步，開獎過程100%公開公正！
                            </p>
                        </div>
                        <div className="triangle"></div>
                    </div>
                    
                </div>
            </div>
        ) 
    }
}
export default withRouter(LotteryMobile)

let nowTime = function (d,id) {
    // let d = new Date();
    let year = d.getFullYear();
    let month = change(d.getMonth() + 1);
    let day = change(d.getDate());
    let hour = change(d.getHours());
    let minute = change(d.getMinutes());
    let second = change(d.getSeconds());
    // console.log(year + '-' + month + '-' + day + ' ' + hour + ':' + minute+':'+second)
    let nows = year + '-' + month + '-' + day + ' ' + hour + ':' + minute+':'+second;
    let today1 = year + '-' + month + '-' + day + ' 21:15:00';  //滚动开始时间
    let today2 = year + '-' + month + '-' + day + ' 21:30:00';  //开奖开始时间
    let today3 = year + '-' + month + '-' + day + ' 21:35:00';  //滚动结束时间
    let today4 = year + '-' + month + '-' + day + ' 21:45:00';  //开奖结束时间
    let today5 = year + '-' + month + '-' + day + ' 21:33:30';  //接口请求开始时间
    let today6 = year + '-' + month + '-' + day + ' 21:40:00';  //接口请求结束时间
    // console.log('当前时间:'+nows,'滚动时间:'+today1,'开奖时间:'+today2,'开奖结束:'+today3)
    if(id=='1'){
        if(nows>today2 && nows<today4){
            // console.log('开奖时间');
            return '开奖时间'
        }else{
            // console.log('其他时间');
            return '其他时间'
        }
    }else if(id=='2'){
        if(nows>today1 && nows<=today3){
            // console.log('滚动时间');
            return '滚动时间'
        }else{
            // console.log('其他时间');
            return '其他时间'
        }
    }else if(id=='3'){
        if(nows>today5 && nows<=today6){
            return '请求时间'
        }else{
            return '其他时间'
        }
    } 
    // return year + '-' + month + '-' + day + ' ' + hour + ':' + minute
  }
  function change(t) {
    if (t < 10) {
      return "0" + t;
    } else {
      return t;
    }
  }
  export default nowTime 
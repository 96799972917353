import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss'
import axios from 'axios';
import Qs from 'qs'
import API from "../../api/api"
import envconfig from "../../api/envconfig";
import nowTime from '../../utils/nowTimes';
import Utils from '../../utils/index'
import Datas from '../../api/data'
import Lottery from './lottery'
import LotteryMobile from './lottery-mobile'
import History from './history'
import { Carousel } from 'antd';
import Swiper from "swiper"
import "swiper/css/swiper.css"
class Home extends Component{
    constructor(props){
        super(props);
        this.state={
            ServerTimeData:[],
            openCode:'',
            CurrentInfoData:[],
            HistoryOpenInfoData:[],
            issue:"",
            openingTime:'其他时间'
        }
    }
    componentDidMount(){
        this.CurrentInfo();
        this.HistoryOpenInfo(2032,6)
        // console.log(Datas)
        new Swiper ('.swiper-container', {
            // direction: 'vertical', // 垂直切换选项
            loop: true, // 循环模式选项
            
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
            },
            
            // 如果需要前进后退按钮
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
            // 如果需要滚动条
            scrollbar: {
              el: '.swiper-scrollbar',
            },
          })
          this.interval = setInterval(()=>{
            let time =nowTime(new Date(),'3');
            if(time == '请求时间'){
                this.CurrentInfo();
            }
          },5000)
          
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
   
    // 信息
    CurrentInfo(){
        axios({
            method: 'post',
            url:envconfig.postUrl+'CurrentInfo',
            data: {
                'lotteryId': 2032
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        CurrentInfoData:res.data.data
                    },()=>{
                        this.IssueOpenInfo(res.data.data.lotteryId,res.data.data.lastIssue)
                        // console.log(this.state.CurrentInfoData)
                    })
                }
            }).catch( err =>{
                console.log(err)
            })
    }
    // 開獎號碼
    IssueOpenInfo(lotteryId,issue){
        axios({
            method: 'post',
            url:envconfig.postUrl+'IssueOpenInfo',
            data: {
                lotteryId: lotteryId,  //彩种id
                issue:issue     //期號
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        openCode:res.data.data.openCode
                    },()=>{
                        // console.log(this.state.openCode)
                    })
                }
            }).catch( err =>{
                console.log(err)
        })
    }
    // 开奖历史（近3期）
    HistoryOpenInfo(lotteryId,issueNum){
        axios({
            method: 'post',
            url:envconfig.postUrl+'HistoryOpenInfo',
            data: {
                lotteryId: lotteryId,  //彩种id
                issueNum:issueNum    //期数
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        HistoryOpenInfoData:res.data.data
                    },()=>{
                        // console.log(this.state.HistoryOpenInfoData)
                    })
                }
            }).catch( err =>{
                console.log(err)
        })
    }
    render(){
        const { CurrentInfoData,openCode,HistoryOpenInfoData} = this.state;
        return(
            <div className="home">
                {/* <iframe src="https://macaujc.com/pc/#/" className="iframes" frameborder="0"></iframe> */}
                <div className="banner">
                    <Carousel autoplay>
                        <div className="bannerpc">
                            <img src={require('../../img/swiper1.jpg')}  alt=""/>
                        </div>
                        <div className="bannerpc">
                            <img src={require('../../img/swiper3.jpg')}  alt=""/>
                        </div>
                        {/* <div>
                            <img src={require('../../img/swiper1.jpg')}  alt=""/>
                        </div>
                        <div>
                            <img src={require('../../img/swiper2.png')}  alt=""/>
                        </div>
                        <div>
                            <img src={require('../../img/swiper3.jpg')}  alt=""/>
                        </div> */}
                    </Carousel>
                </div>
                <div className="banner-mobile">
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <img src={require('../../img/banner1.jpg')}  alt=""/>
                            </div>
                            <div className="swiper-slide">
                                <img src={require('../../img/swiper2.png')}  alt=""/>
                            </div>
                            {/* <div className="swiper-slide">
                                <img src={require('../../img/swiper3.jpg')}  alt=""/>
                            </div> */}
                        </div>
                        {/* <!-- 如果需要分页器 --> */}
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
                <div className='home-center'  >
                    <Lottery 
                        datas={CurrentInfoData}
                        openCode={openCode===null?null:openCode.split(',')}
                        lottery={'澳門六合彩'} 
                    />
                    <History 
                        datas={HistoryOpenInfoData} 
                        lottery="澳門六合彩"
                    />
                </div>
                {/* <div className='home-center' style={{display:"none"}}>
                    <Lottery 
                        datas={Datas.CurrentInfoData.data} 
                        openCode={Datas.IssueOpenInfoData.data.openCode.split(',')}
                        lottery={'澳門十分六合彩'}
                    />
                    <History 
                        datas={Datas.HistoryOpenInfoData.data} 
                        lottery={'澳門十分六合彩'}
                    />
                </div> */}
                <div className="home-center-mobile">
                    <LotteryMobile
                        datas={CurrentInfoData}
                        HistoryDatas={HistoryOpenInfoData} 
                        openCode={openCode===null?null:openCode.split(',')}
                        lottery={'澳門六合彩'} 
                    />
                </div>
            </div>
        )
    }
}
export default Home
import React,{ Component} from 'react'
import moment from 'moment'
import './index.scss'
import Hk6 from '../../../utils/hk6';
import Utils from '../../../utils/index'
import LiveHeader from '../../../component/liveTvHeader';
import LiveTv from '../../../component/liveTv'
import { Input,DatePicker } from 'antd';

const { Search } = Input;
class History extends Component{
    constructor(props){
        super(props);
        this.state={
            days:'1',
            liveTvShow:false,
            liveDtat:{
                issue: "0000000",
                lotteryId: 2032,
                openCode: "00,00,00,00,00,00,00",
                openTime: "0000-00-00 00:00:00",
                pet: "鼠",
                videoUrl: "/lottery/video/2020/2032/2020101.mp4"
                // http://video.58shenghuojia.com/lottery/video/2020/2032/2020101.mp4
            },
            HistoryData:[]
        }
    }
    componentDidMount(){
        // console.log(this.props,'post');
        setTimeout(()=>{
            this.setState({
                HistoryData:this.props.datas
            })
        },2000)
    }
    daysChange(day){
        this.setState({
            days:day
        })
    }
    onChange(date, dateString) {
        // console.log(date, dateString);
    }
    lookLive(data){
        this.setState({
            liveTvShow:true,
            liveDtat:data
        })
    }
    hideLive = (e)=>{
        this.child.playPause('pc');
        this.setState({
            liveTvShow:false
        });
    }
    liveShow(){
        const { liveTvShow,liveDtat } = this.state;
        return(
            <div className='liveShow' style={{display:liveTvShow===true&&liveDtat!=null?'block':'none'}}>
                <div className="contens">
                    <div className="cha" onClick={()=>{this.hideLive()}}>
                        <img src={require('../../../img/cha.png')} alt=""/>
                    </div>
                    <LiveHeader liveDtat={liveDtat} />
                    <LiveTv videoUrl={liveDtat.videoUrl} onRef={(ref)=>{ this.child  = ref}} />
                </div>
            </div>
        )
    }
    tableView(data){
        if(data.length >0){
            return(
                data.map((item,index) =>{
                    return(
                        <div className="table-br table-view" key={index}>
                            <div className="hr1">
                                第<span className='red' >{item.issue}</span>期
                                </div>
                            <div className="hr2">{item.openTime}</div>
                            <ul className="hr3">
                                {
                                    Hk6.lotteryView(item.openCode.split(','))
                                }
                                <li className="add red">+</li>
                            </ul>
                            <div className="hr4">
                                <span onClick={()=>{this.lookLive(item)}}>回放</span>
                            </div>
                        </div>
                    )
                })
            )
        }else{
            return(
                <div className="noDatas"> 暂无数据 </div>
            )
        }
    }
    toParent = (value) => {
        let data = this.props.datas;
        let arr = [];
        let issue = value;
        if(issue){
            if(data.length>0){
                data.forEach(element => {
                    if(element.issue === issue+'' || element.issue.substring(4,7) === issue+''){
                        arr.push(element)
                    }
                });
            }
            this.props.parent.getChildrenMsg(this, arr);
        }else{
            this.props.parent.getChildrenMsg(this, this.state.HistoryData);
        }
    }
    render(){
        const { days} = this.state;
        return(
            <div className="post-history">
                <div className="history-top">
                    <div className="history-name">
                        <span>{this.props.lottery}</span>  開獎公告
                    </div>
                    <div className="top-right">
                        <div style={{display:this.props.lottery==='澳門六合彩'?'block':"none"}} className="right-view">
                                <span className="span_active">今年</span>
                        </div>
                       <div style={{display:this.props.lottery==='澳門十分六合彩'?'block':"none"}} className="right-view">
                            <span className={days==='1'?"span_active":""} onClick={()=>{this.daysChange('1')}}>今天</span>
                            <span className={days==='2'?"span_active":""} onClick={()=>{this.daysChange('2')}}>昨天</span>
                            <span className={days==='3'?"span_active":""} onClick={()=>{this.daysChange('3')}}>前天</span>
                        </div>
                        <DatePicker style={{width: 120 ,borderRadius: 20,display:this.props.lottery==='澳門十分六合彩'?'block':"none"}} 
                            showToday={true}
                            onChange={ ()=>{this.onChange()}} />
                        <Search
                            placeholder="输入期號"
                            onSearch={(value) =>{this.toParent(value)} }
                            style={{ width: 120 ,borderRadius: 20}}
                        />
                    </div>
                </div>
                <div className="history-center">
                    <div className="table-hrader table-view">
                        <span className="hr1">期號</span>
                        <span className="hr2">開獎時間</span>
                        <span className="hr3">中獎號碼</span>
                        <span className="hr4">開獎回放</span>
                    </div>
                    {
                        this.tableView(this.props.datas)
                    }
                </div>
                {
                    this.liveShow()
                }
            </div>
        )
    }
}
export default History
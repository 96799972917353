import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './index.scss'
import Header from '../component/header';
import Foot from '../component/foot';
import Home from '../pages/home';
import About from '../pages/about';
import Contect from '../pages/contect';
import Faq from '../pages/faq';
import Live from '../pages/live';
import Post from '../pages/post'
export default class RouterConfig extends Component{
    render(){
        return(
            <BrowserRouter>
                <Header ></Header>
                <Switch>
                    <div className="content">
                        <Route path="/" component={Home} exact />
                        <Route path="/about" component={About}  />
                        <Route path="/contect" component={Contect}  />
                        <Route path="/faq" component={Faq}  />
                        <Route path="/live" component={Live}  />
                        <Route path="/post" component={Post}  />
                    </div>
                </Switch>
                <Foot></Foot>
            </BrowserRouter>
        )
    }
}
import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss'
import Hk6 from '../../../utils/hk6'
import { Link } from 'react-router-dom'
import {RightOutlined } from '@ant-design/icons';
class History extends Component{
    constructor(props){
        super(props);
        this.state={
            
        }
    }
    componentDidMount(){
        // console.log(this.props)
    }
    historView(){
        let historyList = this.props.datas;
        // console.log(historyList)
        if(historyList.length>0){
            return(
                historyList.map((item,index) =>{
                    return(
                        <div style={{display:index>2?'none':'block'}} key={index}>
                            <p> 第 <span>{item.issue}</span> 期開獎</p>
                            <ul>
                                {
                                    Hk6.lotteryView( item.openCode.split(','))
                                }
                                <li className="add">+</li>
                            </ul>
                        </div>
                    )
                })
             )
        }else{
            return(
                <div  className='noDatas'>暫無數據</div>
            )
        }
        
    }
    render(){
        return(
            <div className="history">
                 <div className={this.props.lottery==='澳門六合彩'?'history-top reds':'history-top blues'} >
                     <p>
                        <span>{this.props.lottery}&nbsp;</span>
                         <span>近期開獎</span>
                     </p>
                     <p>
                         <Link to={'/post'}>更多</Link>
                         {/* <RightOutlined /> */}
                     </p>
                 </div>
                 <div className="history-center">
                 {
                     this.historView()
                 }
                 </div>
                 
            </div>
        )
    }
}
export default History
import React,{ Component} from 'react'
import moment from 'moment'
import axios from 'axios';
import Qs from 'qs'
import API from "../../api/api"
import envconfig from "../../api/envconfig";
import nowTime from '../../utils/nowTimes';
import Util from '../../utils/index'
import Datas from '../../api/data'
import './index.scss'
import History from './history'
import Lottery from './lottery'
import Help from './help'
import LotteryMobile from './lottery-mobile';
import HistoryMobile from './history-mobile'
class Post extends Component{
    constructor(props){
        super(props);
        this.state={
            navIndex:1,
            lottery:"澳門六合彩",
            ServerTimeData:[],
            openCode:null,
            CurrentInfoData:[],
            HistoryOpenInfoData:[],
            childrenMsg:''
        }
    }
    componentDidMount(){
        this.CurrentInfo();
        this.HistoryOpenInfo(2032,Util.Time().substring(0,10));
        this.interval = setInterval(()=>{
            let time =nowTime(new Date(),'3');
            if(time == '请求时间'){
                this.CurrentInfo();
            }
          },5000)
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    navChange(index,lottery){
        this.setState({
            navIndex:index,
            lottery:lottery
        })
    }
    // 信息
    CurrentInfo(){
        axios({
            method: 'post',
            url:envconfig.postUrl+'CurrentInfo',
            data: {
                'lotteryId': 2032
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        CurrentInfoData:res.data.data
                    },()=>{
                        this.IssueOpenInfo(res.data.data.lotteryId,res.data.data.lastIssue)
                        // console.log(this.state.CurrentInfoData)
                    })
                }
            }).catch( err =>{
                console.log(err)
            })
    }
   // 開獎號碼
    IssueOpenInfo(lotteryId,issue){
        axios({
            method: 'post',
            url:envconfig.postUrl+'IssueOpenInfo',
            data: {
                lotteryId: lotteryId,  //彩种id
                issue:issue     //期號
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        openCode:res.data.data.openCode
                    },()=>{
                        // console.log(this.state.openCode)
                    })
                }
            }).catch( err =>{
                console.log(err)
        })
    }
    // 开奖历史（近3期）
    HistoryOpenInfo(lotteryId,issueNum){
        axios({
            method: 'post',
            url:envconfig.postUrl+'HistoryOpenInfo',
            data: {
                lotteryId: lotteryId,  //彩种id
                issueNum:issueNum    //期数
                }
            }).then(res =>{
                // console.log(res);
                if(res.data.code===0){
                    this.setState({
                        HistoryOpenInfoData:res.data.data
                    },()=>{
                        // console.log(this.state.HistoryOpenInfoData)
                    })
                }
            }).catch( err =>{
                console.log(err)
        })
    }
    getChildrenMsg = (result, msg) => {
        // console.log(msg);
        this.setState({
            HistoryOpenInfoData: msg
        })
    }
    render(){
        const {navIndex,lottery} = this.state;
        const { CurrentInfoData,openCode,HistoryOpenInfoData} = this.state;
        return(
            <div className="post">
                <p className="post-name">开奖公告</p>
                <div className="post-nav navHeader" style={{display:'none'}}>
                    <span className={navIndex===1?'span_active':''} onClick={()=>{this.navChange(1,'澳門六合彩')}}>澳門六合彩</span>
                    {/* <span className={navIndex===2?'span_active':''} onClick={()=>{this.navChange(2,'澳門十分六合彩')}}>澳門十分六合彩</span> */}
                    {/* <span className={navIndex===3?'span_active':''} onClick={()=>{this.navChange(3,'')}}>接口調用</span> */}
                </div>
                <div style={{display:navIndex===3?'none':'block'}} className="post-center">
                    <div className='tel'>
                        <span className='tel-name'>重要通知:</span>
                        澳門六合彩於14/5/2020起，攪珠時間變更為每天晚間舉行，並於網絡現場直播。
                        {/* <span><span className='tel-time'>21:30-21:45 </span>(北京時間)，敬請查看。</span> */}
                    </div>
                    <div className='tel-mobile'>
                        <div className='tel-mobile-view'>
                            <div className='tel-name'>重要通知:</div>
                            <div>澳門六合彩於14/5/2020起，攪珠時間變更為每天晚間舉行，並於網絡現場直播。  </div>
                            {/* <div><span className='tel-time'>21:30-21:45 </span>(北京時間)，敬請查看。</div> */}
                        </div>
                    </div>
                    <div className="post-pc">
                        <Lottery 
                            datas={CurrentInfoData}
                            openCode={openCode===null?null:openCode.split(',')}
                            lottery={'澳門六合彩'} 
                            HistoryOpenInfoData={HistoryOpenInfoData} 
                            parent={ this }
                        />
                        <History 
                            datas={HistoryOpenInfoData} 
                            lottery="澳門六合彩"
                            parent={ this }
                        />
                    </div >
                    <div className="post-mobile">
                        <LotteryMobile
                            lottery={lottery}
                            datas={CurrentInfoData} 
                            history={HistoryOpenInfoData}
                            openCode={openCode===null?null:openCode.split(',')}
                            parent={ this }
                        />
                        <HistoryMobile 
                            lottery={lottery}
                            datas={HistoryOpenInfoData} 
                            parent={ this }
                        />
                    </div>
                </div>
                <div style={{display:navIndex===3?'block':'none'}} className="post-center">
                    <Help/>
                </div>
            </div>
        )
    }
}
export default Post
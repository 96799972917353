import React, { PureComponent } from 'react';
import FullScreenVideo from "./full-screen-video";
import "./bg-video.scss";
class BgVideo extends PureComponent {
    constructor(props) {
        super(props)
        this.url = null;
        this.videoW = window.screen.width;
        this.bili = .575;
        this.onMetaDataHandler = this.onMetaDataHandler.bind(this);
        this.pauseVideo = this.pauseVideo.bind(this);
        this.playVideo = this.playVideo.bind(this);
        this.exitFullScreen = this.exitFullScreen.bind(this)
    }

    state = {
        isPlay: false,//暂停播放
        isLiveLoading: true,//是否显示加载
        isFullScreen: false,//是否全屏
        isMobile:window.innerWidth<1024,
        isRefresh:false
    }

    componentDidMount() {
        this.initVideo()
        window.onresize =  ()=> {
            let Width = window.innerWidth//浏览器窗口的内部宽度（包括滚动条）
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            if (Width >= 1024) {
                this.setState({
                    isMobile:false
                },()=>{
                    this.onMetaDataHandler()
                })
            }else{
                this.setState({
                    isMobile:true
                },()=>{
                    this.onMetaDataHandler()
                })
            }
        }
    }

    init() {
        this.initVideo()
    }
    //初始化video
    initVideo() {
        if (window.bgv) {
            this.url = this.props.lotteryId + ".flv";
            window.ltemPlayer || (window.ltemPlayer = window.bgv.createPlayer({
                noMse: true,
                noWork: false,
                noWebgl: false
            }))
            let videoEl = document.getElementById("openLive");
            // let cssText = videoEl.style.cssText;
            // let translatePx = Math.abs(this.state.videoH / 2 - this.state.videoW / 2);
            // cssText += ";transform-origin: center;";
            // cssText += ";transform: rotate(90deg) translate(" + translatePx + "px," + translatePx + "px);width:" + this.state.videoW + "px;height:" + this.state.videoH + "px;";
            // videoEl.style.cssText = cssText;
            videoEl.prepend ? videoEl.prepend(window.ltemPlayer.videoBox) : videoEl.appendChild && videoEl.appendChild(window.ltemPlayer.videoBox);
            this.playVideo()
        }
    }
    //播放
    playVideo() {
        this.setState({
            isPlay: true,
            isLiveLoading: true
        })
        if (window.ltemPlayer) {
            window.ltemPlayer.addEventListener(window.bgv.Event.BGV_DEFAULT_EVENT, this.onHandler);
            window.ltemPlayer.addEventListener(window.bgv.Event.META_DATA_EVENT, this.onMetaDataHandler);
            window.ltemPlayer.play(this.url)
            let videoEl = document.getElementById("openLive");
            videoEl.prepend ? videoEl.prepend(window.ltemPlayer.videoBox) : videoEl.appendChild && videoEl.appendChild(window.ltemPlayer.videoBox);
        }
        if (window.ltemPlayer) {
            window.ltemPlayer.addEventListener(window.bgv.Event.BGV_DEFAULT_EVENT, this.onHandler)
            window.ltemPlayer.addEventListener(window.bgv.Event.META_DATA_EVENT, this.onMetaDataHandler)
            window.ltemPlayer.play(this.url)
        }
    }

    onMetaDataHandler(evt) {
        let wSize = .95 * this.videoW;
        let hSize = this.bili * wSize;
        if(this.state.isMobile){
            window.ltemPlayer.setSize(wSize, hSize);
        }else{
            window.ltemPlayer.setSize(640, 368)
        }
        
        this.setState({
            isLiveLoading: false
        })
    }

    onHandler(evt) { }
    //暂停
    pauseVideo() {
        this.setState({
            isPlay: false,
            isLiveLoading: false
        })
        window.ltemPlayer && window.ltemPlayer.stop()
    }
    //销毁
    stopVideo() {
        this.setState({
            isPlay: false,
            isLiveLoading: false
        })
        if (window.ltemPlayer) {
            window.ltemPlayer.stop();
            window.ltemPlayer.removeEventListener(window.bgv.Event.BGV_DEFAULT_EVENT, this.onHandler);
            window.ltemPlayer.removeEventListener(window.bgv.Event.META_DATA_EVENT, this.onMetaDataHandler);
            window.ltemPlayer = null;
            setTimeout(() => {
                let videoEl = document.getElementById("openLive");
                if (videoEl && videoEl.removeChild) {
                    let bgvideo = document.getElementsByTagName("bgvideo")[0];
                    videoEl.removeChild(bgvideo)
                }
            }, 80)
        }
    }
    //全屏
    openFullScreen(e) {
        this.stopVideo()
        this.setState({
            isFullScreen: true
        })

    }

    exitFullScreen() {
        this.setState({
            isFullScreen: false
        }, () => {
            this.initVideo()
        })
    }
    //卸载销毁视频
    componentWillUnmount() {
        this.stopVideo()
    }
    //刷新
    onRefresh(){
        this.initVideo()
        this.setState({
            isRefresh:true
        })
        setTimeout(()=>{
            this.setState({
                isRefresh:false
            })
        },1200)
    }

    render() {
        const { isPlay, isLiveLoading, isFullScreen,isMobile,isRefresh } = this.state
        return (
            <div className="bgVideoBox">
                {
                    isFullScreen ? <FullScreenVideo isMobile={isMobile} exitFullScreen={this.exitFullScreen} lotteryId={this.props.lotteryId}></FullScreenVideo> : <div id="openLive" className="open-live" onClick={isPlay ? this.pauseVideo : this.playVideo} style={isMobile?{}:{width: "640px",height: "368px"}}>
                        {
                            isLiveLoading ? <div className="spinner">
                                <div className="rect1"></div>
                                <div className="rect2"></div>
                                <div className="rect3"></div>
                            </div> : null
                        }
                        {isPlay ? null : <div class="play"></div>}
                        <div className="open-fullScreen-btn" onClick={(e) => { e.stopPropagation(); this.openFullScreen() }}>
                            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAApCAYAAACsldDLAAABtElEQVRYhe2ZMU4DMRBFfyJOQBfKNNTJGaAINRVtUtAgSjgBCfehgysALQcg3jTkBmiQkVdovYO94/XaLvIlS47XGT/NesdjG0S0ICJF4VLGBhwlyhgjXQEwQT9VAE4cFqKMMY5gRGsUwYZPk3EEI1sAS0+fFYBd34H0NCDH8z2AMwDvfQcKVIPN59ljAC8AZplgG+JgH6zf5QAzYUK3bZj2LyKaeUJU7NIC49pcwPMSYUsAFsHmBhbD5gQOgs0FHAzrAp6mgJUut/cAHq02HYevIkbTfxWSG3DAHwMy/snKMyvBK1oR0RMR3Q44ZxtsuuHCQH6a+pBft7Q02HTWleQNxlCMfPYAy+kAO5Q07MLsPpWpl6QmmxXL1IBh6MbE5evAOKukuUFoubTGWKfIDUJ1av1PL9lrsa1Enp2a7MyWz8MtsBSwMJtNKXA22BDgrLBS4OywEuAiYLsCFwMLs9nkgDcc21HmpfUNwDmAZ7OXq3UH4NvuzB15pjgYtjVngFsqJeuqPbx3deJgSVi6ZGt19uSy/erzrO/ku6tSXID8eraKAJtinu80bN/LiRQXIFsAyx868tihJWjVJwAAAABJRU5ErkJggg==" alt="" />
                        </div>
                        {/* <div className={`${"refresh-btn"} ${isRefresh===true?'refresh-btn-active':''}`} 
                        onClick={()=>{this.onRefresh()}}>
                        </div> */}
                    </div>
                }

            </div>

        )
    }
}
export default BgVideo
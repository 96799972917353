/**
 * 配置编译环境和线上环境之间的切换
 * postUrl: 爬虫地址
 */

let postUrl = '';
if (process.env.NODE_ENV === 'development') {
  // postUrl = "https://wwwamlhc.com/"
  // postUrl = "https://his.macaujc.com/api/"
  postUrl = "https://am6h.pxklos.com/api/"
  // postUrl = 'https://api.9gsport.com/api/'
  // postUrl = 'http://192.168.0.31:2379/api/'
} else if (process.env.NODE_ENV === 'production') {
  postUrl = window.location.origin+'/api/';
  // postUrl = "https://his.macaujc.com/api"
  // postUrl = "https://his.macaujc.com/api/"
}

export default {
  postUrl
}

// 获取当前时间
let Time = function () {
  let d = new Date();
  let year = d.getFullYear();
  let month = change(d.getMonth() + 1);
  let day = change(d.getDate());
  let hour = change(d.getHours());
  let minute = change(d.getMinutes());
  let second = change(d.getSeconds());
  let week = getWeekDate()
  return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ' ' + week
}
// 获取当前时间
let Tomorrow = function () {
  let day1 = new Date().setTime(new Date().getTime()+24*60*60*1000);
  // day1.setTime(day1.getTime()+24*60*60*1000);
  let tomorRow = timestampToTime(day1)
  return tomorRow
}
/**
	 *获取当前星期几
	 *
	*/
function getWeekDate() {
  let now = new Date();
  let day = now.getDay();
  let weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
  let week = weeks[day];
  return week;
}
// 时间戳转换成时间格式
let timestampToTime = function (timestamp) {
  let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  let hh = date.getHours() + ":";
  let mm =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  let ss =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // return Y + M + D + hh + mm + ss;
  return Y + M + D;
}
// 获取当前时间（判断开始和结束时间,）
let timeAnalysis = function () {
  let time = {
    start: null,
    end: null,
  }
  let d = new Date();
  let hour = d.getHours();
  let timestamp = new Date().getTime();
  if (hour >= 12) {
    time.start = timestampToTime(timestamp) + '12:00:00';
    time.end = timestampToTime(timestamp + 24 * 60 * 60 * 1000) + "11:59:59"
  } else {
    time.start = timestampToTime(timestamp - 24 * 60 * 60 * 1000) + '12:00:00';;
    time.end = timestampToTime(timestamp) + "11:59:59"
  }
  return time
}

function ttime(now) {
  let year = new Date(now).getFullYear();
  let month = new Date(now).getMonth() + 1;
  let date = new Date(now).getDate();
  if (month < 10) month = "0" + month;
  if (date < 10) date = "0" + date;
  return year + "-" + month + "-" + date
}
let query = function (way) {
  let d = new Date();
  let hour = d.getHours();
  let getTimes;
  if (hour >= 12) {
    getTimes = 0
  } else {
    getTimes = 24 * 60 * 60 * 1000
  }
  let timeSlot = {
    startTime: "",
    endTime: ""
  };
  let startTime, endTime;
  if (way === '0') {
    startTime = ttime(new Date().getTime() - getTimes)
    endTime = ttime(new Date().getTime() - getTimes);
  } else if (way === '1') {
    startTime = ttime(new Date().getTime() - 24 * 60 * 60 * 1000 - getTimes);
    endTime = ttime(new Date().getTime() - 24 * 60 * 60 * 1000 - getTimes);
  } else if (way === '2') {
    startTime = showWeekFirstDay();
    endTime = ttime(new Date().getTime());
  } else if (way === '3') {
    startTime = ttime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - new Date().getDay() - 6));
    endTime = ttime(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + (6 - new Date().getDay() - 6)));
  } else if (way === '4') {
    startTime = ttime(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    endTime = ttime(new Date().getTime());
  } else if (way === '5') {
    startTime = ttime(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1));
    endTime = ttime(new Date(new Date().getFullYear(), new Date().getMonth() - 1, getMonthDays(new Date().getMonth() - 1)))
  }
  timeSlot.startTime = startTime;
  timeSlot.endTime = endTime;
  return timeSlot
}
function change(t) {
  if (t < 10) {
    return "0" + t;
  } else {
    return t;
  }
}

//本周第一天；
function showWeekFirstDay() {
  let Nowdate = new Date();
  let WeekFirstDay = new Date(Nowdate - (Nowdate.getDay() - 1) * 86400000);
  let M = Number(WeekFirstDay.getMonth()) + 1;
  if (M < 10) {
    M = "0" + M;
  }
  let D = WeekFirstDay.getDate();
  if (D < 10) {
    D = "0" + D;
  }
  return WeekFirstDay.getFullYear() + "-" + M + "-" + D;
}
//本周最后一天
function showWeekLastDay() {
  let Nowdate = new Date();
  let WeekFirstDay = new Date(Nowdate - (Nowdate.getDay() - 1) * 86400000);
  let WeekLastDay = new Date((WeekFirstDay / 1000 + 6 * 86400) * 1000);
  let M = Number(WeekLastDay.getMonth()) + 1;
  if (M < 10) {
    M = "0" + M;
  }
  let D = WeekLastDay.getDate();
  if (D < 10) {
    D = "0" + D;
  }
  return WeekLastDay.getFullYear() + "-" + M + "-" + D;
}
//获得某月的天数：
function getMonthDays(myMonth) {
  let monthStartDate = new Date(new Date().getFullYear(), myMonth, 1);
  let monthEndDate = new Date(new Date().getFullYear(), myMonth + 1, 1);
  let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
  return days;
}
// 深拷贝
function deepCopy(obj) {
  let result = Array.isArray(obj) ? [] : {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        result[key] = deepCopy(obj[key]);   //递归复制
      } else {
        result[key] = obj[key];
      }
    }
  }
  return result;
}
let TimeDown = function (params) {
  //结束时间
  let endDate = new Date(params.replace(/-/g, "/"));
  //当前时间
  let nowDate = new Date();
  //相差的总秒数
  let totalSeconds = parseInt((endDate - nowDate) / 1000);
  //天数
  let days = Math.floor(totalSeconds / (60 * 60 * 24));
  //取模（余数）
  let modulo = totalSeconds % (60 * 60 * 24);
  //小时数
  let hours = Math.floor(modulo / (60 * 60));
  modulo = modulo % (60 * 60);
  //分钟
  let minutes = Math.floor(modulo / 60);
  //秒
  let seconds = modulo % 60;
  return days + "天" + hours + "小时" + minutes + "分钟" + seconds + "秒"
  //延迟一秒执行自己
  setTimeout(() => {
    this.TimeDown(params);
  }, 1000)
}

//函数调用，输入string类型的时间格式
// TimeDown('2021-01-01 00:00:000')
//随机变换开奖数组内索引
let shuffle = function () {
  let arr1 = ['', '', '', '', '', '', ''];
  let arr2 = [
    "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
    "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
    "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
    "41", "42", "43", "44", "45", "46", "47", "48", "49"
  ]
  let rand = Math.floor(Math.random() * arr2.length) + 1;
  rand < 10 ? rand = '0' + rand : rand = '' + rand;
  arr1.forEach((element, index) => {
    arr1.push(Math.floor(Math.random() * arr2.length) + 1 + "")
  })
  // console.log(arr1.slice(7),'随机数')
  return arr1.slice(7)
}

// setInterval(() => {
//   shuffle()
// }, 500);

// 直播时间计算
let fivTime = function (name) {
  let weeks = Time().substring(17);
  let time = Time().substring(0, 16);
  let day = Time().substring(0, 10);
  // console.log(weeks,time,day);
  if (time > day + " " + "21:30" && time < day + " 21:45") {
    return true
  } else {
    return false
  }
}



export default { Time, timestampToTime, timeAnalysis, query, deepCopy, TimeDown, shuffle, fivTime,Tomorrow }
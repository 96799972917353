import React,{Component} from 'react';
import './index.scss'
import Hk6 from '../../../utils/hk6';
import LiveHeader from '../../../component/liveTvHeader';
import LiveTv from '../../../component/liveTv'
class HistoryMobile extends Component{
    constructor(porps){
        super(porps);
        this.state={
            days:'1',
            liveTvShow:false,
            liveDtat:{
                issue: "0000000",
                lotteryId: 2032,
                openCode: "00,00,00,00,00,00,00",
                openTime: "0000-00-00 00:00:00",
                pet: "鼠",
                videoUrl: ""
                // http://video.58shenghuojia.com/lottery/video/2020/2032/2020101.mp4
            },
        }
    }
    componentDidMount(){
        // console.log(this.props)
    }
    lookLive(data){
        this.setState({
            liveTvShow:true,
            liveDtat:data
        })
    }
    hideLive = (e)=>{
        this.child.playPause('mobile');
        this.setState({
            liveTvShow:false
        });
    }
    liveShow(){
        const { liveTvShow,liveDtat } = this.state;
        return(
            <div className='liveShow' style={{display:liveTvShow===true&&liveDtat!=null?'block':'none'}}>
                <div className="contens">
                    <div className="cha" onClick={()=>{this.hideLive()}}>
                        <img src={require('../../../img/chaMobile.png')} alt=""/>
                    </div>
                    <LiveHeader liveDtat={liveDtat} />
                    <LiveTv videoUrl={liveDtat.videoUrl} onRef={(ref)=>{ this.child  = ref}} />
                </div>
            </div>
        )

    }
    liveList(){
        const tvList = this.props.datas;
        if(tvList.length > 0){
            return(
                tvList.map( (item,index )=>{
                    return(
                        <div className='post-history-list' key={index}>
                            <div className="left">
                                <p>期号</p>
                                <p>開獎時間</p>
                                <p>中獎號碼</p>
                            </div>
                            <div className="center">
                                <p>第{item.issue}期</p>
                                <p>{item.openTime}</p>
                                <ul className="lotteryNum">
                                    {
                                        Hk6.lotteryView(item.openCode.split(','))
                                    }
                                    <li className="add">+</li>
                                </ul>
                            </div>
                            <div className='right'>
                                <img onClick={()=>{this.lookLive(item)}}  src={require('../../../img/liveMobile.png')} alt=""/>
                            </div>
                            
                        </div>
                    )
                })
            )
        }
    }
    render(){
        return(
            <div className="post-history-mobile">
                <div className="post-history-mobile-nav">
                    <span>今年</span>
                </div>
                <div>
                    {
                        this.liveList()
                    }
                </div>
                <div className='post-history-mobile-foot'>
                    <span>沒有數據了</span>
                </div>
                {
                    this.liveShow()
                }
            </div>
        )
    }
}
export default HistoryMobile
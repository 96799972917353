import React, { PureComponent } from 'react';
import "./full-screen-video.scss"
class FullScreenVideo extends PureComponent {
    constructor(props) {
        super(props)
        this.url = null;
        this.videoW = window.innerHeight;
        this.videoH = window.innerWidth;
        this.onMetaDataHandler = this.onMetaDataHandler.bind(this);
        this.pauseVideo = this.pauseVideo.bind(this);
        this.playVideo = this.playVideo.bind(this);
        this.exitFullScreen = this.exitFullScreen.bind(this)
    }

    
    componentDidMount() {
        this.initVideo()
        window.onresize =  ()=> {
            let Width = window.innerWidth//浏览器窗口的内部宽度（包括滚动条）
                || document.documentElement.clientWidth
                || document.body.clientWidth;
            if (Width >= 1024) {
                this.onMetaDataHandler()
            }else{
                this.onMetaDataHandler()
            }
        }
    }


    exitFullScreen() {
        this.props.exitFullScreen()

    }

    init() {
        this.initVideo()
    }

    initVideo() {
        const isMobile = this.props.isMobile;
        if (window.bgv) {
            this.url = this.props.lotteryId + ".flv";
            window.ltemPlayer || (window.ltemPlayer = window.bgv.createPlayer({
                noMse: true,
                noWork: false,
                noWebgl: false
            }))
            console.log(window.ltemPlayer)
            let videoEl = document.getElementById("full-screen-openLive");
            if(isMobile){
                let cssText = videoEl.style.cssText;
                let translatePx = Math.abs(this.videoH / 2 - this.videoW / 2);
                cssText += ";transform-origin: center;";
                cssText += ";transform: rotate(90deg) translate(" + translatePx + "px," + translatePx + "px);width:" + this.videoW + "px;height:" + this.videoH + "px;";
                videoEl.style.cssText = cssText;
            }else{

            }
            
            videoEl.prepend ? videoEl.prepend(window.ltemPlayer.videoBox) : videoEl.appendChild && videoEl.appendChild(window.ltemPlayer.videoBox);
            console.log(videoEl.prepend, window.ltemPlayer.videoBox)
            this.playVideo()
        }
    }

    playVideo() {
        this.setState({
            isPlay: true,
            isLiveLoading: true
        })
        if (window.ltemPlayer) {
            window.ltemPlayer.addEventListener(window.bgv.Event.BGV_DEFAULT_EVENT, this.onHandler)
            window.ltemPlayer.addEventListener(window.bgv.Event.META_DATA_EVENT, this.onMetaDataHandler)
            window.ltemPlayer.play(this.url)
        }
    }

    onMetaDataHandler(evt) {
        if(this.props.isMobile){
            window.ltemPlayer.setSize(this.videoW, this.videoH);
        }else{
            window.ltemPlayer.setSize(this.videoH,this.videoW);
        }
        this.setState({
            isLiveLoading: false
        })
    }

    onHandler() { }

    pauseVideo() {
        this.setState({
            isPlay: false,
            isLiveLoading: false
        })
        window.ltemPlayer && window.ltemPlayer.stop()
    }

    //销毁
    stopVideo() {
        this.setState({
            isPlay: false,
            isLiveLoading: false
        })
        if (window.ltemPlayer) {
            window.ltemPlayer.stop();
            window.ltemPlayer.removeEventListener(window.bgv.Event.BGV_DEFAULT_EVENT, this.onHandler);
            window.ltemPlayer.removeEventListener(window.bgv.Event.META_DATA_EVENT, this.onMetaDataHandler);
            window.ltemPlayer = null;
            setTimeout(() => {
                let videoEl = document.getElementById("full-screen-openLive");
                if (videoEl && videoEl.removeChild) {
                    let bgvideo = document.getElementsByTagName("bgvideo")[0];
                    videoEl.removeChild(bgvideo)
                }
            }, 80)
        }
    }

    //卸载销毁视频
    componentWillUnmount() {
        this.stopVideo()
    }

    render() {
        return (
            <div className="full-screen">
                <div className="open-live" id="full-screen-openLive" style={this.props.isMobile?{}:{height:this.videoW+"px",width:this.videoH+"px"}}>
                    <div className="badge">
                        直播
                    </div>
                    <div class="exit-fullScreen-btn" onClick={(evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();
                        this.exitFullScreen(evt)
                    }}>
                        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAApCAYAAACsldDLAAABx0lEQVRYhe2YMU4DMRBF/0Y5AR2UaaiTM0ARKgpoaKGgoYYTkOUulHRwBeAClLBJE26APvLKK20WZz127NiR+NJIWcuZeR5Z47FB0temJCttU4uPZq6v6hiNsxuSTySvHcDbwSuHud7AytFZZ3AmhO0qNiwHAA6xqjsAM4TVFYDFxh5JjkguDQuxZdgls75mDDD2AE4G6wOcFNYVODmsC3AWsFLgbGCVTdYAl6lghz1V7Q3AMYBnAHut8VsAP4HrsEiFWoFFEwOwSUUEvhW4geAPTYa/I8A4ScFOAVR6FevsVZDZ6CrqbgbYDxAoi20g0eZNilnz1uhiEKAj+gJwGYO0xVbHkFSDbBRqG2xF/7CxtHOwzaFQ6d8xJDl4+qxm6x4Kqq4dRIANcfDMC/6tXdFPIk8tdmXP1odCXz+7Lalu7gjAuy1eDplV3dwLgLFtYirY+863DDjFXUqPlYbxpb6sGv2khO0DnuQI6wScA6wYOBdYEXBOsFbg3GD7gEemOuvVEQWswerl/cFQhy9MjYyPbN2aT7NU6qeqRudDHWjT9s0WvB1DepNWGf4AcKqfrx7VHjkhOTfsE6k+tY++fdjEkMw1G4lfFlmE0D1p2q0AAAAASUVORK5CYII=" alt="" />
                        
                    </div>
                    <div className='bags'></div>
                </div>


            </div>
        )
    }
}
export default FullScreenVideo
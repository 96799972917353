import React,{Component} from 'react'
import './index.scss';
import hk6 from '../../utils/hk6'
class liveHeader extends Component{
    constructor(porps){
        super(porps);
        this.state={
            
        }
    }
    componentDidMount(){
        // console.log(this.props)
    }
    con(){
        // console.log(this.props)
    }
    render(){
        let liveData = this.props.liveDtat;
        return(
            <div className="liveHeader"> 
                <div className="liveHeader-nav" onClick={()=>{this.con()}}>
                    <span className='lottert-name'>{liveData.lotteryId===2032?'澳門六合彩':'澳門六合彩 '}</span>
                    <span>第 <span className='red'>{liveData.issue}</span> 期</span>
                    <span className='videoTitle'>回放</span>
                </div>
                <div className="liveHeader-center">
                    <div className="liveHeader-time">
                        <span>開獎時間：{liveData.openTime}</span>
                    </div>
                    <div className="liveHeader-mobile-time">
                        <span>{liveData.openTime}</span>
                    </div>
                    <div className="liveHeader-openCode">
                        <span>開獎號碼：</span>
                        <ul className='lotteryNum live-lotteryNum'>
                            {
                                hk6.lotteryView(liveData.openCode.split(','))
                            }
                            <li className="add">+</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default liveHeader
import React,{ Component} from 'react'
// import moment from 'moment'
import './index.scss'
import Hk6 from '../../../utils/hk6'

class HistoryMobile extends Component{
    constructor(props){
        super(props);
        this.state={
            
        }
    }
    componentDidMount(){
        // console.log(this.props)
    }
    historView(){
        let historyList = this.props.datas;
        // console.log(historyList)
        if(historyList.length>0){
            return(
                historyList.map((item,index) =>{
                    return(
                        <div className='history-mobile-list' style={{display:index>4?'none':'block'}} key={index}>
                            <div className='history-mobile-issue'>第<span className='red'>{item.issue}</span>期</div>
                            <ul className='lotteryNum'>
                                {
                                    Hk6.lotteryView( item.openCode.split(','))
                                }
                                <li className="add">+</li>
                            </ul>
                        </div>
                    )
                })
             )
        }else{
            return(
                <div className='noDatas'>暫無數據</div>
            )
        }
        
    }
    render(){
        return(
            <div className='history-mobile'>
                {
                    this.historView()
                }
            </div>
        )
    }
}
export default HistoryMobile
import Server from './server';
import URL from "./envconfig";
class API extends Server {

  /**
   *  用途：获取开奖倒计时
   *  @method Pots
   *  @return {promise}
   */
  async ServerTime(params = "") {
    try {
      let result = await this.axios('post', '/ServerTime',params);
      if (result) {
        return result;
      } else {
        let err = {
          tip: '获取开奖倒计时',
          response: result,
          data: params
        }
        throw err;
      }
    } catch (err) {
      throw err;
    }
  }
  /**
   *  用途：获取当前信息
   *  @method post
   *  @return {promise}
   */
  async CurrentInfo(params) {
    try {
      let result = await this.axios('post', '/CurrentInfo',params);
      if (result) {
        return result;
      } else {
        let err = {
          tip: '获取当前信息',
          response: result,
          data:params
        }
        throw err;
      }
    } catch (err) {
      throw err;
    }
  }
   /**
   *  用途：获取当前开奖
   *  @method post
   *  @return {promise}
   */
  async IssueOpenInfo(params = "") {
    try {
      let result = await this.axios('post', '/IssueOpenInfo',params);
      if (result) {
        return result;
      } else {
        let err = {
          tip: '获取当前开奖',
          response: result,
          data: params
        }
        throw err;
      }
    } catch (err) {
      throw err;
    }
  }
  /**
   *  用途：获取开奖历史
   *  @method post
   *  @return {promise}
   */
  async HistoryOpenInfo(params = "") {
    try {
      let result = await this.axios('post', '/HistoryOpenInfo',params);
      if (result) {
        return result;
      } else {
        let err = {
          tip: '获取最近三期開獎',
          response: result,
          data: params
        }
        throw err;
      }
    } catch (err) {
      throw err;
    }
  }
}

export default new API();
import React,{ Component} from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Util from '../../utils'
import './index.scss'


class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            routerList:[
                {
                    name:"首頁",
                    path:"/",
                    icon:"foot1"
                },{
                    name:"開獎公告",
                    path:"/post",
                    icon:"foot2"
                },{
                    name:"開獎視頻",
                    path:"/live",
                    icon:"foot3"
                },{
                    name:"FAQ",
                    path:"/faq",
                    icon:"foot4"
                },{
                    name:"關於我們",
                    path:"/about",
                    icon:"foot5"
                }
                // ,{
                //     name:"聯繫我們",
                //     path:"/contect",
                    
                // },
            ],
            routerListFoot:[
                {
                    name:"首頁",
                    path:"/",
                    icon:"foot1"
                },{
                    name:"開獎公告",
                    path:"/post",
                    icon:"foot2"
                },{
                    name:"開獎視頻",
                    path:"/live",
                    icon:"foot3"
                },{
                    name:"FAQ",
                    path:"/faq",
                    icon:"foot4"
                },{
                    name:"關於我們",
                    path:"/about",
                    icon:"foot5"
                }
            ],
            time:"0000-00-00 00:00",
            routers:"",
        }
    }
    //生命周期（开始）
    componentDidMount(){
        this.setState({
            routers:window.location.pathname,
        })
        
        setInterval(() => {
            this.setState({time:Util.Time()})
        }, 1000);
    }
    // 生命周期（销毁） 
    componentWillUnmount() {
        // alert('componentWillUnmount');
        // console.log('componentWillUnmount');
    }
    pathChange(path){
        this.setState({
            routers:path,
        })
    }
    
    render(){
        const { time,routerList,routers,routerListFoot} = this.state;
        return(
            <div className="header">
                <div className="top">
                    <div className="top-pc">
                        <img src={require('../../img/logo_lg.png')}  alt=""/>
                        <p>{time}</p>
                    </div>
                    <div className="top-mobile">
                        <img src={require('../../img/logo_lg.png')}  alt=""/>
                        <p>{time}</p>
                    </div>
                </div>
                <div className="navs">
                    {routerList.map((item,index)=> {
                        return(
                            <Link to={item.path} key={index} 
                            style={{color:routers===item.path?'#fff100':''}}
                            onClick={()=>{this.pathChange(item.path)}}
                            >{item.name}</Link>
                        )
                    })}
                </div>
                <div className="foot-nav">
                    {routerListFoot.map((item,index)=> {
                        return(
                            <Link to={item.path} key={index} 
                            style={{backgroundColor:routers===item.path?'#d80011':''}}
                            onClick={()=>{this.pathChange(item.path)}}
                            >
                                <img src={require(`../../img/${item.icon}.png`)} alt=""/>
                                <span>{item.name}</span>
                            </Link>
                        )
                    })}
                </div>
            </div>
        )
    }
}
export default Header
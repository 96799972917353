import React, { Component } from 'react'
import moment from 'moment'
import './index.scss'


class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ss: "{"
        }
    }
    render() {
        return (
            <div className='help'>
                <div className='help-top'>
                    接口調用
                </div>
                <div className="main">
                    <div>本網站提供查詢接口，供查詢開獎數據。</div>
                    <div> 地址: http://api.bjjfnet.com/data/opencode/:id</div>
                    <div> 方式: GET</div>
                    <div> 返回: JSON</div>
                    <div className="mark">調用示例</div>
                    <div>1、澳門六合彩: http://api.bjjfnet.com/data/opencode/2032</div>
                    {/* <div>  2、澳門十分六合彩: http://api.bjjfnet.com/data/opencode/2031 </div> */}
                    <div className="mark">返回示例</div>
                    <div>查詢澳門六合彩開獎數據</div>
                    <div>  {this.state.ss} </div>
                    <div className="left1" >
                        "code": 0,
                    </div>
                    <div className="left1" >
                                        "message": "Success",
                    </div>
                    <div className="left1">
                                        "data": [
                    </div>
                    <div className="left2">
                        }
                    </div>
                    <div className="left3">
                                        "issue": "2020070",
                    </div>
                    <div className="left3">
                                        "openCode": "19,16,06,49,21,07,11",
                    </div>
                    <div className="left3">
                                        "openTime": "2020-03-10 21:34:30"
                    </div>
                    <div className="left2">
                                        },
                    </div>
                    <div className="left2">
                                        ...
                    </div>
                    <div className="left1">
                                        ]
                    </div>
                    <div>
                                        }
                    </div>
                    <div className="mark">
                                        返回字段說明
                    </div>
                    <div>
                                        1、code: 消息編碼, 0 代表成功；
                    </div>
                    <div>
                                        2、message: 消息描述；
                    </div>
                    <div>
                                        3、data：具體開獎數據；issue：期號；openCode：開獎號碼；openTime：開獎時間。
                    </div>
                </div>
            </div>
        )
    }
}
export default Help


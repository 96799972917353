import React,{ Component} from 'react'
import {withRouter} from "react-router-dom";
// import moment from 'moment'
import './index.scss'
import Hk6 from '../../../utils/hk6'
import Utils from '../../../utils/index';
import nowTime from '../../../utils/nowTimes';
import CountDown from '../../../component/countDown'
import { Tooltip, Button } from 'antd';
class Lottery extends Component{
    constructor(props){
        super(props);
        this.state={
            lottery:'',
            openingTime:"其他时间"
        }
    }
    componentDidMount(){
        // console.log(this.props)
        // this.saveOpeningTime();
        this.interval = setInterval(()=>{
            let inter;
            let tiem = nowTime(new Date(),'2');
            this.setState({
              openingTime:tiem
            },()=>{
                // console.log(this.state.openingTime)
            })
            if(tiem==='滚动时间'){
                inter = setInterval(() => {
                    this.setState({
                        lottery:Utils.shuffle()
                    }) 
                }, 100);
            }else {
                clearInterval(inter); 
                this.setState({
                    lottery:this.props.openCode
                })
            }
        },1000)
    }
    routerPath(name){
        this.props.history.push(name)
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    render(){
        // let lottery=['33','40','05','47','49','24','18']
        let  lotterys = this.props.openCode;
        // let  lottery = Utils.shuffle();
        const {lottery,openingTime} = this.state;
        let endTime="00-00-00 00:00:00";
        if(this.props.datas.endTime){
            endTime = this.props.datas.endTime.substring(0,10)+" 21:15:00"
        }
        // console.log(endTime)
        return(
            <div className="lottery">
                <div className={this.props.lottery==='澳門六合彩'?'lottery-top reds':'lottery-top blues'} >
                    <div >{this.props.lottery}</div>
                    <div>
                        {/* {console.log(this.props.datas.endTime)} */}
                        第 <span>{this.props.datas.issue?this.props.datas.issue:'********'}</span> 期開獎時間：<span>{endTime}</span>
                    </div>
                </div>
                <div className="lottery-center">
                    <div className="center-left">
                        <p>
                            <span className="lottery-name">{this.props.datas.lotteryName}</span>
                            <span>第 <span className="lottery-stage">{this.props.datas.lastIssue?this.props.datas.lastIssue:"********"}</span> 期</span>
                        </p>
                        <ul className="lotteryView">
                            {
                                openingTime==='滚动时间'?Hk6.lotteryView(lottery):Hk6.lotteryView(lotterys)
                            }
                            <li className="add">+</li>
                        </ul>
                    </div>
                    <div className="center-right">
                        <CountDown CountDown={this.props.datas.endTime}/>
                        <div className="buts">
                            <button className="toPots">开奖验证
                                    <div className="pupViews">
                                        <div className="pup-top">
                                            <img src={require('../../../img/tan.png')} alt=""/>
                                            <span>即時開獎驗證</span>
                                        </div>
                                        <div>
                                            <p>
                                            開獎現場直播，與中央電視台1套同步，開獎過程100%公開公正！
                                            </p>
                                        </div>
                                        <div className="triangle"></div>
                                    </div>
                            </button>
                            <button className="toLive" onClick={()=>{this.routerPath('/live')}} >直播</button>
                        </div>
                        <p onClick={()=>{this.routerPath('/post')}}>
                            <span>歷史開獎查詢 </span>
                            <span >></span>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Lottery)
// 开奖历史
let LiveURL = 'https://video.58shenghuojia.com'
let HistoryOpenInfoData = {
    code: 0,
    data: [
        {
            issue: "2020099",
            lotteryId:2032,
            openCode:"48,22,46,27,43,16,38",
            openTime: "2020-04-08 21:34:30",
            pet:"鼠",
            videoUrl:"/lottery/video/2020/2032/2020099.mp4"
        },{
            issue:"2020098",
            lotteryId:2032,
            openCode:"33,40,05,47,49,24,18",
            openTime:"2020-04-07 21:34:31",
            pet:"鼠",
            videoUrl:"/lottery/video/2020/2032/2020098.mp4"
        },{
            issue:"2020097",
            lotteryId:2032,
            openCode:"12,48,46,08,35,02,37",
            openTime:"2020-04-06 21:34:44",
            pet:"鼠",
            videoUrl:"/lottery/video/2020/2032/2020097.mp4"
        },
        {
            issue: "2020099",
            lotteryId:2032,
            openCode:"48,22,46,27,43,16,38",
            openTime: "2020-04-08 21:34:30",
            pet:"鼠",
            videoUrl:"/lottery/video/2020/2032/2020099.mp4"
        },{
            issue:"2020098",
            lotteryId:2032,
            openCode:"33,40,05,47,49,24,18",
            openTime:"2020-04-07 21:34:31",
            pet:"鼠",
            videoUrl:"/lottery/video/2020/2032/2020098.mp4"
        },{
            issue:"2020097",
            lotteryId:2032,
            openCode:"12,48,46,08,35,02,37",
            openTime:"2020-04-06 21:34:44",
            pet:"鼠",
            videoUrl:"/lottery/video/2020/2032/2020097.mp4"
        }
    ],
    message:"Success"
}
// 彩种信息
let CurrentInfoData = {
    code: 0,
    data: {
        beginTime:"2020-04-08 21:30:00",
        endTime:"2020-04-09 21:30:00",
        isMaintain:0,
        issue:"2020100",
        lastIssue:"2020099",
        lotteryId:2032,
        lotteryName:"澳門六合彩",
        pet:"鼠",
        serverTime:"2020-04-09 10:27:14"
    },
    message:"Success"
}
// 開獎號碼
let IssueOpenInfoData = {
    code: 0,
    data: {
        issue:"2020099",
        lotteryId:2032,
        openCode:"48,22,46,27,43,16,38"
    },
    message:"Success"
}
// 开奖倒计时
let ServerTimeData = {
    code: 0,
    data: {
        BJSeviceTime:"2020-04-09 10:27:15",
        ETSeviceTime:"2020-04-08 22:27:15"
    },
    message:"Success"
}
export default {ServerTimeData,IssueOpenInfoData,CurrentInfoData,HistoryOpenInfoData,LiveURL}
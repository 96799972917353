import React, { PureComponent } from 'react';
// import Reflv from 'reflv';

class HttpFlv extends PureComponent {
  render() {
    return (<div></div>
      // <Reflv
      //   url={`http://ws3-vd1.royefu.com/live/2032-2.flv`}  // 2032:六合彩 2031:十分六合彩 
      //   type="flv"
      //   isLive
      //   cors
      // />
    )
  }
}
export default HttpFlv
import React,{Component} from 'react'
import './index.scss';
import Datas from '../../api/data'
class LiveTv extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        // console.log(this.props.videoUrl);
        this.props.onRef(this)
    }
    playPause(pc){
        console.log(pc)
            let myVideo = document.getElementById("video2");
            // console.log(myVideo)
            myVideo.pause(); 
            let myVideom = document.getElementById("video3");
            // console.log(myVideom)
            myVideom.pause();
    }
    render(){
        return(
            <div className="liveTv">
                <div className='liveTv-pc'>
                    <video id="video2" width="640" height="360" controls src={Datas.LiveURL+this.props.videoUrl} type="video/mp4" >
                        {/* <source src={Datas.LiveURL+this.props.videoUrl} type="video/mp4" /> */}
                        {/* <source src={Datas.LiveURL+this.props.videoUrl} type="video/ogg" /> */}
                    </video>
                    <div className='bages'></div>
                </div>
                <div className='liveTv-mobile'>
                {/* <div onClick={()=>{this.playPause('mobile')}} >暂停</div> */}
                    <video id="video3" width="100%" height="100%" controls src={Datas.LiveURL+this.props.videoUrl} type="video/mp4" >
                        {/* <source src={Datas.LiveURL+this.props.videoUrl} type="video/mp4" /> */}
                        {/* <source src={Datas.LiveURL+this.props.videoUrl} type="video/ogg" /> */}
                        
                    </video>
                    <div className='bages'></div>
                </div>
            </div> 
        )
    }
}
export default LiveTv
import React,{ Component} from 'react'
import moment from 'moment'
import './index.scss'
import Hk6 from '../../../utils/hk6';
import Utils from '../../../utils/index';
import nowTime from '../../../utils/nowTimes';
import CountDown from '../../../component/countDown'
class Lottery extends Component{
    constructor(props){
        super(props);
        this.state={
            lottery:'',
            ss:"1",
            openingTime:"其他时间"
        }
    }
    componentDidMount(){
        // console.log(this.props)
        this.interval = setInterval(()=>{
            let inter;
            let tiem = nowTime(new Date(),'2');
            this.setState({
              openingTime:tiem
            },()=>{
                // console.log(this.state.openingTime)
            })
            if(tiem==='滚动时间'){
                inter = setInterval(() => {
                    this.setState({
                        lottery:Utils.shuffle()
                    }) 
                }, 100);
            }else{
                clearInterval(inter); 
                this.setState({
                    lottery:this.props.openCode
                })
            }
        },1000)
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    render(){
        let  lotterys = this.props.openCode;
        const { lottery,openingTime } = this.state;
        return(
            <div className="post-lottery">
                <div className='lottery-left'>
                    <div >
        <span className="lottery-name"> {this.props.lottery}</span>第  <span className="red">{this.props.datas.lastIssue?this.props.datas.lastIssue:'********'}</span> 期開獎號碼
                    </div>
                    <ul>
                        {
                            openingTime==='滚动时间'?Hk6.lotteryView(lottery):Hk6.lotteryView(lotterys)
                        }
                        <li className="add">+</li>
                    </ul>
                </div>
                <div className='lottery-rigth'>
                    <div className="lottery-rigth-top">
                        <span className="red">{this.props.datas.issue?this.props.datas.issue:'********'}</span>
                         期截止時間：
                         <span className="red">{this.props.datas.endTime?this.props.datas.endTime.substring(0,10)+' 21:30:00':'0000-00-00 00:00:00'}</span>
                    </div>
                    <CountDown  CountDown={this.props.datas.endTime}/>
                </div>
            </div>
        )
    }
}
export default Lottery
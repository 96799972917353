import React,{Component} from 'react';
import { Accordion, List } from 'antd-mobile';
import './index.scss'
class AccordionExmple extends Component {

  onChange = (key) => {
    console.log(key);
  }
  render() {
    let textList =[
        {
            name:"1、澳門六合彩在什麼時間段可以購買？",
            testx:"答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。"
        },{
            name:"2、需要多少錢才可以購買？",
            testx:"答：澳門六合彩注項單位為每注10澳幣。"
        },{
            name:" 3、單張彩票可以購買多少金額？",
            testx:"答：購買者可對其選定的投注號碼進行單倍或多倍投注，投注倍數範圍為1-100倍。單張彩票的投注金額最高不得超過1000澳幣。"
        },{
            name:"4、人在外地是否可以購買澳門六合彩？",
            testx:"答：很抱歉，澳門六合彩只在區域性固定站點銷售。"
        },{
            name:"5、我應如何確認是否中獎？",
            testx:"答：您可在澳門六合彩的銷售站點查詢確認、或在澳門六合彩官網的「開獎公告」處查詢核對中獎號碼。"
        },{
            name:"6、如何能進一步確認開獎結果的正確性？",
            testx:"答：您可在澳門六合彩官網查看即時開獎公告與開獎視頻，或查看歷史開獎期號的視頻回放。"
        },{
            name:"7、購買彩券後可以退款不買嗎？",
            testx:"答：抱歉，根據《奖券规例》規定獎券彩票一經發出給參加者或經參加者確認，參加者不得以任何理由更換、取消或撤回。"
        },{
            name:"8、中獎之後領獎的步驟是什麼樣的？",
            testx:"答：所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。逾期未兌獎視為棄獎，棄獎獎金納入彩票公益金。"
        },{
            name:"9、如果我的彩券受到污染或損壞是否可以兌獎？",
            testx:"答：根據《奖券规例》規定任何獎券彩票（包括損毀彩票）不會獲得派發獎金或發還退款，除非該彩票可憑其獨有的序列號碼辨證。"
        }
    ]
    return (
      <div className='mian-mobile' style={{ marginTop: 10, marginBottom: 10 }}>
          <div className="mian-mobile-header">FAQ</div>
        <Accordion  className="my-accordion" onChange={this.onChange}>
          <Accordion.Panel header="1、澳門六合彩在什麼時間段可以購買？">
            <p>答：澳門六合彩票為區域性彩種，請根據站點的具體營運時間購買。</p>
          </Accordion.Panel>
          <Accordion.Panel header="2、需要多少錢才可以購買？" className="pad">
            <p>答：澳門六合彩注項單位為每注10澳幣。</p>
          </Accordion.Panel>
          <Accordion.Panel header="3、單張彩票可以購買多少金額？" className="pad">
            <p>答：購買者可對其選定的投注號碼進行單倍或多倍投注，投注倍數範圍為1-100倍。單張彩票的投注金額最高不得超過1000澳幣。</p>
          </Accordion.Panel>
          <Accordion.Panel header="4、人在外地是否可以購買澳門六合彩？" className="pad">
            <p>答：很抱歉，澳門六合彩只在區域性固定站點銷售。</p>
          </Accordion.Panel>
          <Accordion.Panel header="5、我應如何確認是否中獎？" className="pad">
            <p>答：您可在澳門六合彩的銷售站點查詢確認、或在澳門六合彩官網的「開獎公告」處查詢核對中獎號碼。</p>
          </Accordion.Panel>
          <Accordion.Panel header="6、如何能進一步確認開獎結果的正確性？" className="pad">
            <p>答：您可在澳門六合彩官網查看即時開獎公告與開獎視頻，或查看歷史開獎期號的視頻回放。</p>
          </Accordion.Panel>
          <Accordion.Panel header="7、購買彩券後可以退款不買嗎？" className="pad">
            <p>答：抱歉，根據《奖券规例》規定獎券彩票一經發出給參加者或經參加者確認，參加者不得以任何理由更換、取消或撤回。</p>
          </Accordion.Panel>
          <Accordion.Panel header="8、中獎之後領獎的步驟是什麼樣的？" className="pad">
            <p>答：所有獲中彩票，必須於有關攪珠的日期後起計60天內領取 (例子：於3月1日所舉行的攪珠的獲中彩票，可於3月1日當天至4月30日內任何一天領取)。逾期未兌獎視為棄獎，棄獎獎金納入彩票公益金。</p>
          </Accordion.Panel>
          <Accordion.Panel header="9、如果我的彩券受到污染或損壞是否可以兌獎？" className="pad">
            <p>答：根據《奖券规例》規定任何獎券彩票（包括損毀彩票）不會獲得派發獎金或發還退款，除非該彩票可憑其獨有的序列號碼辨證。</p>
          </Accordion.Panel>
        </Accordion>
      </div>
    );
  }
}
export default AccordionExmple
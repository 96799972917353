import React,{Component} from 'react'
import './index.scss';
import Hk6 from '../../../utils/hk6';
import Utils from '../../../utils/index';
import nowTime from '../../../utils/nowTimes';
import CountDown from '../../../component/countDown'
class LotteryMobile extends Component{
    constructor(props){
        super(props);
        this.state={
            lottery:'',
            HistoryData:"",
            openingTime:"其他时间"
        }
    }
    componentDidMount(){
        console.log(this.props);
        setTimeout(()=>{
            this.setState({
                HistoryData:this.props.history,
                lottery:this.props.openCode
            })
        },500);
        this.interval = setInterval(()=>{
            let inter;
            let tiem = nowTime(new Date(),'2');
            this.setState({
              openingTime:tiem
            },()=>{
                // console.log(this.state.openingTime)
            })
            if(tiem==='滚动时间'){
                inter = setInterval(() => {
                    this.setState({
                        lottery:Utils.shuffle()
                    }) 
                }, 100);
            }else{
                clearInterval(inter); 
                this.setState({
                    lottery:this.props.openCode
                })
            }
        },1000)
        
    }
    componentWillUnmount(){
        clearInterval(this.interval);
    }
    toParent = (e) => {
        // console.log(e.target.value)
        let data = this.props.history;
        let arr = [];
        let issue = e.target.value;
        if((data.length>0 && issue.length===3)){
            data.forEach(element => {
                if((element.issue).substring(4,7) == issue+'' ){
                    arr.push(element)
                }
            });
            this.props.parent.getChildrenMsg(this, arr);
        } 
        else if((data.length>0 && issue.length===7) ){
            data.forEach(element => {
                if(element.issue === issue+''){
                    arr.push(element)
                }
            });
            this.props.parent.getChildrenMsg(this, arr);
        }
        else {
            this.props.parent.getChildrenMsg(this, this.state.HistoryData);
        }
    }
    render(){
        let lotterys = this.props.openCode;
        // console.log(lotterys)
        const { lottery,openingTime } = this.state;
        return(
            <div className='post-lottery-mobile'>
                <div className="lottery-mobile-top">
                    <div className='lottery-mobile-name'>
                        <div>澳門六合彩</div>
                        <div>開獎公告</div>
                    </div>
                    <div className='lottery-mobile-input'>
                        <input type="text" placeholder='開獎查詢' onChange={(e)=>{this.toParent(e)}} />
                        <img src={require('../../../img/fdj.png')} alt=""/>
                    </div>
                </div>
                <div className="lottery-mobile-center">
                    <div className='lottery-info'>
                        <p>第<span className='red' >{this.props.datas.lastIssue?this.props.datas.lastIssue:'********'} </span> 期開獎號碼</p>
                        <ul className="lotteryNum">
                            {
                                openingTime==='滚动时间'?Hk6.lotteryView(lottery):Hk6.lotteryView(lotterys)
                            }
                            <li className="add">+</li>
                        </ul>
                    </div>
                    <div className='lottery-info'>
                        <p>第<span className='red'>{this.props.datas.lastIssue? Number(this.props.datas.lastIssue)+1 :'********'}</span> 期開獎時間<span className='red' >{this.props.datas.endTime?this.props.datas.endTime.substring(0,10)+" 21:30:00":'0000-00-00 00:00:00'}</span> </p>
                        <CountDown CountDown={this.props.datas.endTime}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default LotteryMobile
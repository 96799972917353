import React,{ Component} from 'react'
var lotteryNumbers;
//生肖计算
let saveSx= function(nums){
    // let sx =['鼠','牛','虎','兔','龍','蛇','馬','羊','猴','雞','狗','豬'];
    let sx =['鼠','豬','狗','雞','猴','羊','馬','蛇','龍','兔','虎','牛'];
    let inds;
    if(nums){
        inds = (Number(nums)-1)%12;
        return sx[inds]
    }else{
        return ''
    }
    
    
}
//背景颜色判断
let saveBk= function(nums){
    let red=['01','1','02','2','07','8','7','08','12','13','18','19','23','24','29','30','34','35','40','45','46'];
    let blue=['03','3','04','4','09','9','10','14','15','20','25','26','31','36','37','41','42','47','48'];
    let green=['05','5','06','6','11','16','17','21','22','27','28','32','33','38','39','43','44','49'];
    let color;
    if(red.indexOf(nums) != -1){
        // color='red';
        return(
            <img src={require('../img/red.png')} alt=""/>
        )
    }else if(blue.indexOf(nums) != -1){
        color='blue';
        return(
            <img src={require('../img/blue.png')} alt=""/>
        )
    }else if(green.indexOf(nums) != -1){
        color='green';
        return(
            <img src={require('../img/green.png')} alt=""/>
        )
    }else{
        return(
            <img src={require('../img/bk0.png')} alt=""/>
        )
    }
    
}


// 开奖转换
let lotteryView = function(data){
    // console.log(data);
    let noDatas = ['00','00','00','00','00','00','00'];
    if(data==="" || data===null){
        return(
            noDatas.map((item,index) => {
                return(
                    <li key={index}>
                        <span>{''}</span>
                        {
                           this.saveBk(item)
                        }
                        {/* <p>{this.saveSx(item)}</p> */}
                    </li>
                )
            })
        )
    }else{
        // if(Array.isArray(data) === false && data.lottery.length>0){
        //     return(
        //         data.lottery.map((item,index) => {
        //             return(
        //                 <li key={index}>
        //                     <span>{ Number(item) <10?"0"+item:item}</span>
        //                     {
        //                        this.saveBk(item)
        //                     }
        //                     <p>{this.saveSx(item)}</p>
        //                 </li>
        //             )
        //         })
        //     )
        // }else{
            return(
                data.map((item,index) => {
                    return(
                        <li key={index}>
                            <span>{(Number(item) <10 && item.length==1)?"0"+item:item}</span>
                            {
                               this.saveBk(item)
                            }
                            <p>{this.saveSx(item)}</p>
                        </li>
                    )
                })
            )
        // }
    }
    
}
export default {saveSx,saveBk,lotteryView}
import React from 'react';
import { connect } from 'react-redux'
import Router from './router'
import './App.scss';
import 'antd/dist/antd.css'; 
import 'antd-mobile/dist/antd-mobile.css';
function App() {
  return <Router />
}

export default App;
